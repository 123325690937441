import {COOKIE_POLICY_PATH, COOKIE_SETTINGS_PAGE_PATH} from './paths';
import {resolveUrl} from './util';

/**
 * The URL for the cookie settings page, hosted by mkt-srv, which allows users to change
 * their permissions.
 */
export const COOKIE_SETTINGS_PAGE_URL = resolveUrl(COOKIE_SETTINGS_PAGE_PATH);

/**
 * The URL for our cookie policy.
 */
export const COOKIE_POLICY_URL = resolveUrl(COOKIE_POLICY_PATH);

/**
 * The API endpoint which can be called to determine the enforcement mode for the current user.
 */
export const ENFORCEMENT_MODE_URL =
  'https://stripe.com/cookie-settings/enforcement-mode';
