{
  "standalone_onramp.AddThisToYourSite": "¿Te interesa añadirlo a tu sitio web?",
  "standalone_onramp.ChooseBetween": "Elige entre crédito, débito y banco.",
  "standalone_onramp.CookieSettings": "Configuración de cookies",
  "standalone_onramp.FastAndSecure": "Rápido y seguro",
  "standalone_onramp.FlexibleWaysToPay": "Formas de pago flexibles",
  "standalone_onramp.GetStarted": "Empezar",
  "standalone_onramp.Help": "Ayuda",
  "standalone_onramp.InstantlyBuyCrypto": "Comprar criptomonedas de forma instantánea",
  "standalone_onramp.LearnMore": "Más información",
  "standalone_onramp.LowFees": "Comisiones bajas",
  "standalone_onramp.PayFaster": "Paga más rápido con el cifrado de datos que cumple con los estándares de PCI más exigentes disponibles.",
  "standalone_onramp.Privacy": "Privacidad",
  "standalone_onramp.SaveUpTo": "Ahorra hasta el 50 % en comisiones en el proceso de compra.",
  "standalone_onramp.Terms": "Condiciones",
  "standalone_onramp.ErrorMessage": "Se ha producido un error al descargar la aplicación. Vuelve a intentarlo en unos minutos."
}