{
  "standalone_onramp.AddThisToYourSite": "Norite tai naudoti savo svetainėje?",
  "standalone_onramp.ChooseBetween": "Pasirinkite kredito kortelę, debeto kortelę arba banką.",
  "standalone_onramp.CookieSettings": "Slapukų nustatymai",
  "standalone_onramp.FastAndSecure": "Greita ir saugu",
  "standalone_onramp.FlexibleWaysToPay": "Lankstūs mokėjimo būdai",
  "standalone_onramp.GetStarted": "Pradėti",
  "standalone_onramp.Help": "Pagalba",
  "standalone_onramp.InstantlyBuyCrypto": "Iškart įsigykite kriptovaliutų",
  "standalone_onramp.LearnMore": "Sužinoti daugiau",
  "standalone_onramp.LowFees": "Maži mokesčiai",
  "standalone_onramp.PayFaster": "Mokėkite greičiau naudodami aukščiausius PCI standartus atitinkantį duomenų šifravimą.",
  "standalone_onramp.Privacy": "Privatumas",
  "standalone_onramp.SaveUpTo": "Atsiskaitydami sutaupykite iki 50 % mokesčių.",
  "standalone_onramp.Terms": "Sąlygos",
  "standalone_onramp.ErrorMessage": "Įkeliant paraiškos formą įvyko klaida. Po kelių minučių bandykite dar kartą."
}