import {SessionStorageItem} from './SessionStorageItem';
import MANIFEST from '../../../sessionStorage.yaml';

const items = Object.keys(MANIFEST).reduce<{
  [manifestKeys: string]: SessionStorageItem;
}>((hash, name) => {
  const manifestValue = MANIFEST[name] as SessionStorageItem;
  hash[name] = new SessionStorageItem(name, manifestValue);
  return hash;
}, {});

const inexactItems = Object.keys(items)
  .filter((name) => items[name].inexact)
  .map((name) => items[name]);

/**
 * Contains information about all known session storage items, loaded from
 * pay-server/cookies/sessionStorage.yaml. Only items which have entries in the
 * manifest are allowed to be set.
 * @private
 */
export const SessionStorageManifest = {
  /**
   * Gets the SessionStorageItem definition for the specified item from the manifest.
   * @param {string} name The name of the item to retrieve.
   * @returns The matching SessionStorageItem, or null if no match was found.
   */
  get(name: string): SessionStorageItem | null {
    // First, try to match the item's name exactly.
    if (items[name]) return items[name];

    // If we didn't find an exact match, try to match by pattern instead.
    for (const item of inexactItems) {
      if (item.isMatch(name)) return item;
    }

    return null;
  },
} as const;
