{
  "standalone_onramp.AddThisToYourSite": "Vuoi aggiungerlo al tuo sito?",
  "standalone_onramp.ChooseBetween": "Scegli tra carta di credito, carta di debito e conto bancario.",
  "standalone_onramp.CookieSettings": "Impostazioni dei cookie",
  "standalone_onramp.FastAndSecure": "Veloce e sicuro",
  "standalone_onramp.FlexibleWaysToPay": "Modalità di pagamento flessibili",
  "standalone_onramp.GetStarted": "Inizia",
  "standalone_onramp.Help": "Guida",
  "standalone_onramp.InstantlyBuyCrypto": "Acquista criptovaluta in modo immediato",
  "standalone_onramp.LearnMore": "Ulteriori informazioni",
  "standalone_onramp.LowFees": "Commissioni basse",
  "standalone_onramp.PayFaster": "Paga più velocemente con la crittografia dei dati e soddisfa gli standard PCI più severi disponibili.",
  "standalone_onramp.Privacy": "Privacy",
  "standalone_onramp.SaveUpTo": "Risparmia fino al 50% sulle commissioni al momento del pagamento.",
  "standalone_onramp.Terms": "Termini",
  "standalone_onramp.ErrorMessage": "Si è verificato un errore durante il caricamento dell'applicazione. Riprova tra qualche minuto."
}