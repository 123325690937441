{
  "standalone_onramp.AddThisToYourSite": "Szeretné hozzáadni a weboldalához?",
  "standalone_onramp.ChooseBetween": "Válasszon hitelkártya, betéti kártya és bank közül.",
  "standalone_onramp.CookieSettings": "Sütibeállítások",
  "standalone_onramp.FastAndSecure": "Gyors és biztonságos",
  "standalone_onramp.FlexibleWaysToPay": "Rugalmas fizetési módok",
  "standalone_onramp.GetStarted": "Kezdés",
  "standalone_onramp.Help": "Segítség",
  "standalone_onramp.InstantlyBuyCrypto": "Kriptovaluta vásárlása azonnal",
  "standalone_onramp.LearnMore": "Bővebben itt",
  "standalone_onramp.LowFees": "Alacsony díjak",
  "standalone_onramp.PayFaster": "Fizessen gyorsabban a PCI adatbiztonsági szabvány legszigorúbb előírásainak megfelelő adattitkosítással.",
  "standalone_onramp.Privacy": "Adatvédelem",
  "standalone_onramp.SaveUpTo": "Fizetéskor takarítsa meg a díjak 50%-át.",
  "standalone_onramp.Terms": "Feltételek",
  "standalone_onramp.ErrorMessage": "Hiba történt az alkalmazás betöltése közben, próbálja újra néhány perc múlva."
}