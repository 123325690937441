{
  "standalone_onramp.AddThisToYourSite": "Haluaisitko lisätä tämän sivustollesi?",
  "standalone_onramp.ChooseBetween": "Valitse luottokortin, pankkikortin tai pankin välillä.",
  "standalone_onramp.CookieSettings": "Evästeasetukset",
  "standalone_onramp.FastAndSecure": "Nopea ja turvallinen",
  "standalone_onramp.FlexibleWaysToPay": "Joustavia maksutapoja",
  "standalone_onramp.GetStarted": "Aloita",
  "standalone_onramp.Help": "Ohje",
  "standalone_onramp.InstantlyBuyCrypto": "Osta kryptovaluuttaa välittömästi",
  "standalone_onramp.LearnMore": "Lue lisää",
  "standalone_onramp.LowFees": "Alhaiset hinnat",
  "standalone_onramp.PayFaster": "Maksa nopeammin palvelimien tietojen salauksella, joka täyttää tiukimmat PCI-standardit.",
  "standalone_onramp.Privacy": "Tietosuoja",
  "standalone_onramp.SaveUpTo": "Säästä jopa 50 % maksuista kassalla.",
  "standalone_onramp.Terms": "Ehdot",
  "standalone_onramp.ErrorMessage": "Sovelluksen latauksen aikana tapahtui virhe. Yritä uudelleen muutaman minuutin kuluttua."
}