/* eslint-disable no-use-before-define */
// ESLint doesn't like how the Permissions class uses its own class type as
// a return signature on its own functions, which is strange. We're just
// disabling the rule in this file as it shouldn't affect runtime logic
// as long as the linter is unhappy with the type only.

import jscookie from 'js-cookie';
import {Categories, Category, CategoryValue} from './Categories';
import {SET_PERMISSIONS_PATH} from './paths';
import {isLinkUrl, log, resolveUrl} from './util';

export const PERMISSIONS_COOKIE_NAME = 'cookie-perms';

/**
 * The API endpoints to call to change the user's permissions on domains other than stripe.com.
 */
const TLD_COOKIE_PERMISSION_URLS = [
  'https://c.stripe.dev/cookie',
  'https://c.stripe.global/cookie',
  'https://c.stripe.partners/cookie',
  'https://c.increment.com/cookie',
];

// Type information is lost when using Object.keys as TypeScript isn't
// smart enough to figure out Categories' keys are of type Category.
const CATEGORY_KEYS = Object.keys(Categories) as Category[];

/**
 * Represents a set of permissions set for a user. Normally, you shouldn't
 * need to work with this directly. Instead, you should use {Cookies},
 * {LocalStorage}, {SessionStorage}.
 * @param {Date} timestamp The timestamp to use.
 * @param {Map} permissions The permissions map to use.
 */
export class Permissions {
  _version: string;

  _permissions: Partial<Record<Category, boolean>>;

  _timestamp: Date;

  constructor(
    version: string,
    timestamp: Date,
    permissions: Partial<Record<Category, boolean>>,
  ) {
    this._version = version;
    this._permissions = permissions;
    this._timestamp = timestamp;
  }

  /**
   * Returns the version deserialized from the cookie-perms cookie.
   */
  get version() {
    return this._version;
  }

  /**
   * Returns the timestamp at which the permissions were set.
   */
  get timestamp() {
    return this._timestamp;
  }

  /**
   * Determines whether the specified storage category is allowed.
   * @param {Category} category The category in question
   * @returns True if items belonging to the category can be written, otherwise false.
   */
  get(category: Category) {
    return this._permissions[category];
  }

  /**
   * Returns a map of all permissions. The keys of the map are the storage categories,
   * and each value is a Boolean representing whether the category is allowed.
   * @returns The map of permissions.
   */
  getAll(): Partial<Record<Category, boolean>> {
    return {...this._permissions};
  }

  /**
   * Serializes the Permissions object to a string which can be written to a cookie.
   */
  toString(): string {
    const tokens: string[] = CATEGORY_KEYS.reduce<string[]>((array, name) => {
      const category: CategoryValue = Categories[name];

      // If the category exists, is not necessary, and has permissions,
      // attach the category token to the array.
      if (category && !category.necessary && this._permissions[name]) {
        return [...array, category.token];
      }

      // Otherwise ignore the category.
      return array;
    }, []).sort();

    const seconds = Math.floor(this.timestamp.valueOf() / 1000);
    return `${this._version}:${seconds}|${tokens.join('')}`;
  }

  /**
   * Deserializes a Permissions object from a string representation.
   * @param {str} str The serialized representation
   * @returns The deserialized Permissions instance.
   */
  static deserialize(str: string): Permissions {
    const [version, payload] = str.split(':', 2);
    const segments = payload.split('|');

    const timestamp = new Date(Number(segments[0]) * 1000);
    const tokens = segments[1].split('');

    const permissions = CATEGORY_KEYS.reduce<
      Partial<Record<Category, boolean>>
    >((hash, name) => {
      const category = Categories[name];
      if (category.necessary) {
        // If the category is considered necessary, permissions are implicit.
        hash[name] = true;
      } else if (category.token) {
        // Otherwise, check for the category's token in the permission list.
        hash[name] = tokens.indexOf(category.token) >= 0;
      }
      return hash;
    }, {});

    return new Permissions(version, timestamp, permissions);
  }

  /**
   * Gets the user's current permissions set, if one has been set.
   * @returns A Permissions instance representing the user's permissions.
   */
  static get(): Permissions | null {
    try {
      // Try to read and deserialize permissions from the permissions cookie.
      // We avoid caching the deserialized permissions to ensure that we're
      // always in sync with the cookie; for example, it's possible that the
      // user makes a change to the cookie in a different tab.
      const str = jscookie.get(PERMISSIONS_COOKIE_NAME);
      if (str) return Permissions.deserialize(str);
    } catch (err) {
      // If we encounter any errors trying to deserialize the permissions,
      // treat them as though they don't exist.
    }

    return null;
  }

  /**
   * Sets the specified permissions for the user by making fetch() calls to the cookie
   * permissions API endpoints.
   * @param {Map} changedPermissions The permissions to change
   * @returns A promise for a Permissions instance representing the new permissions.
   */
  static async set(
    changes: Partial<Record<Category, boolean>>,
  ): Promise<Permissions | null> {
    // Because WebKit (ITP >2.1) restricts the lifetime of cookies set via JS to a maximum of
    // 7 days, the permissions cookies need to be set via HTTP response header instead. Also,
    // because we need to control cookies being set on several TLDs, we need to make
    // calls to multiple endpoints to set these cookies.
    const tryCallPermissionsEndpoint = async (url: string) => {
      try {
        return await fetch(url, {
          method: 'post',
          body: JSON.stringify(changes),
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'cookies',
          },
        });
      } catch (error) {
        // Trap errors to ensure the rest of the requests continue even if one fails.
        if (error instanceof Error) {
          log.error(error.message);
        } else {
          log.error('Unexpected error', error);
        }
      }
    };

    // The server will set the new cookie, so we wait for the requests to complete
    // and then look at the value of the cookie to get the new permissions.
    const setPermissionUrl = resolveUrl(SET_PERMISSIONS_PATH);
    if (isLinkUrl(setPermissionUrl)) {
      // Only the link.co domain permissions endpoint needs to be called for Link domains.
      await tryCallPermissionsEndpoint(setPermissionUrl);
    } else {
      // For all other domains, we need to call the permissions endpoint for each TLD.
      await Promise.all(
        [setPermissionUrl, ...TLD_COOKIE_PERMISSION_URLS].map(
          tryCallPermissionsEndpoint,
        ),
      );
    }

    // If you're wondering why localStorage and sessionStorage are not removed
    // from the browser storage here, the logic is handled inside their
    // respective wrapper classes LocalStorage and SessionStorage.

    return Permissions.get();
  }

  /**
   * Set cookie permissions for the user, allowing all cookies.
   * @returns A promise for a Permissions instance representing the new permissions.
   */
  static async allowAll(): Promise<Permissions | null> {
    const permissions = CATEGORY_KEYS.reduce<
      Partial<Record<Category, boolean>>
    >((hash, name) => {
      const category = Categories[name];
      if (!category.necessary) hash[name] = true;
      return hash;
    }, {});

    return Permissions.set(permissions);
  }

  /**
   * Set cookie permissions for the user, blocking all unnecessary cookies.
   * @returns A promise for a Permissions instance representing the new permissions.
   */
  static async rejectAll(): Promise<Permissions | null> {
    const permissions = CATEGORY_KEYS.reduce<
      Partial<Record<Category, boolean>>
    >((hash, name) => {
      const category = Categories[name];
      if (!category.necessary) hash[name] = false;
      return hash;
    }, {});

    return Permissions.set(permissions);
  }
}
