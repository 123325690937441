{
  "standalone_onramp.AddThisToYourSite": "Tertarik menambahkannya ke situs Anda?",
  "standalone_onramp.ChooseBetween": "Pilih antara kredit, debit, dan bank.",
  "standalone_onramp.CookieSettings": "Pengaturan Cookie",
  "standalone_onramp.FastAndSecure": "Cepat dan aman",
  "standalone_onramp.FlexibleWaysToPay": "Cara fleksibel untuk membayar",
  "standalone_onramp.GetStarted": "Mulai",
  "standalone_onramp.Help": "Bantuan",
  "standalone_onramp.InstantlyBuyCrypto": "Beli kripto secara instan",
  "standalone_onramp.LearnMore": "Pelajari selengkapnya",
  "standalone_onramp.LowFees": "Biaya rendah",
  "standalone_onramp.PayFaster": "Bayar lebih cepat dengan enkripsi data yang memenuhi standar PCI paling tinggi yang tersedia.",
  "standalone_onramp.Privacy": "Privasi",
  "standalone_onramp.SaveUpTo": "Hemat hingga 50% biaya saat checkout.",
  "standalone_onramp.Terms": "Ketentuan",
  "standalone_onramp.ErrorMessage": "Terjadi kesalahan saat memuat aplikasi. Coba lagi dalam beberapa menit."
}