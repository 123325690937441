{
  "standalone_onramp.AddThisToYourSite": "Bunu sitenize eklemek ister misiniz?",
  "standalone_onramp.ChooseBetween": "Kredi, borç ve banka arasında seçim yapın.",
  "standalone_onramp.CookieSettings": "Çerez Ayarları",
  "standalone_onramp.FastAndSecure": "Hızlı ve güvenli",
  "standalone_onramp.FlexibleWaysToPay": "Esnek ödeme yöntemleri",
  "standalone_onramp.GetStarted": "Başlayın",
  "standalone_onramp.Help": "Yardım",
  "standalone_onramp.InstantlyBuyCrypto": "Anında kripto satın alın",
  "standalone_onramp.LearnMore": "Daha fazla bilgi",
  "standalone_onramp.LowFees": "Düşük ücretler",
  "standalone_onramp.PayFaster": "Mevcut en yüksek PCI standartlarını karşılayan veri şifreleme ile daha hızlı ödeme yapın.",
  "standalone_onramp.Privacy": "Gizlilik",
  "standalone_onramp.SaveUpTo": "Ödeme sırasında ücretlerde %50'ye kadar tasarruf edin.",
  "standalone_onramp.Terms": "Koşullar",
  "standalone_onramp.ErrorMessage": "Uygulama yüklenirken hata oluştu. Lütfen birkaç dakika içinde tekrar deneyin."
}