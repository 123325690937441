{
  "standalone_onramp.AddThisToYourSite": "귀하의 사이트에 이를 추가하시겠습니까?",
  "standalone_onramp.ChooseBetween": "신용 카드, 직불 카드, 은행 이체 중에서 선택하십시오.",
  "standalone_onramp.CookieSettings": "쿠키 설정",
  "standalone_onramp.FastAndSecure": "안전 및 보안",
  "standalone_onramp.FlexibleWaysToPay": "유연한 결제 방법",
  "standalone_onramp.GetStarted": "사용 개시",
  "standalone_onramp.Help": "도움말",
  "standalone_onramp.InstantlyBuyCrypto": "즉시 암호화폐 구매",
  "standalone_onramp.LearnMore": "자세히 알아보기",
  "standalone_onramp.LowFees": "낮은 수수료",
  "standalone_onramp.PayFaster": "가장 높은 PCI 표준을 충족하는 데이터 암호화로 더 빠르게 비용을 지불하십시오.",
  "standalone_onramp.Privacy": "개인 정보 보호",
  "standalone_onramp.SaveUpTo": "체크아웃 시 수수료를 최대 50% 절약할 수 있습니다.",
  "standalone_onramp.Terms": "약관",
  "standalone_onramp.ErrorMessage": "애플리케이션을 로드하는 동안 오류가 발생했습니다. 몇 분 후에 다시 시도하십시오."
}