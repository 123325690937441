{
  "standalone_onramp.AddThisToYourSite": "Kas soovite selle oma saidile lisada?",
  "standalone_onramp.ChooseBetween": "Saate krediitkaardi, deebetkaardi ja panga vahel valida.",
  "standalone_onramp.CookieSettings": "Küpsisesätted",
  "standalone_onramp.FastAndSecure": "Kiire ja turvaline",
  "standalone_onramp.FlexibleWaysToPay": "Paindlikud viisid maksmiseks",
  "standalone_onramp.GetStarted": "Alusta",
  "standalone_onramp.Help": "Spikker",
  "standalone_onramp.InstantlyBuyCrypto": "Kohene krüptovaluuta ostmine",
  "standalone_onramp.LearnMore": "Lisateave",
  "standalone_onramp.LowFees": "Madalad tasud",
  "standalone_onramp.PayFaster": "Kiirema maksmise tagab andmekrüptimine, mis vastab kõige kõrgematele PCI standarditele.",
  "standalone_onramp.Privacy": "Privaatsus",
  "standalone_onramp.SaveUpTo": "Kuni 50 % väiksemad tasud makse lõpuleviimisel.",
  "standalone_onramp.Terms": "Tingimused",
  "standalone_onramp.ErrorMessage": "Taotluse laadimisel ilmnes tõrge. Proovige mõne minuti pärat uuesti."
}