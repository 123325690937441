import {PermissionsAwareStorageManager} from '../PermissionsAwareStorageManager';
import {EnforcementMode} from '../types';
import {isStorageAvailable, log} from '../util';
import {SessionStorageManifest} from './SessionStorageManifest';

/**
 * Configuration options which can be passed to the constructor of SessionStorage.
 */
export type SessionStorageConfig = {
  /**
   * By default, the library will make an XHR call to determine whether the user is currently
   * located in an "enforcement zone" where cookie permissions are required before setting cookies.
   * Passing a value for enforcementMode will skip this call and use the specified value instead.
   */
  enforcementMode?: EnforcementMode;
};

/**
 * Allows reading and writing of session storage items while honoring a user's permissions.
 * @param {SessionStorageConfig} config Optional configuration options.
 */
export class SessionStorage extends PermissionsAwareStorageManager {
  constructor(config: SessionStorageConfig = {}) {
    super(config);

    // Refresh the stored values when this object is initialized.
    // refresh() is an async void function because it can take time to load
    // enforcement mode, and the return value is not important.
    if (isStorageAvailable('sessionStorage')) {
      // Note that we preserve this by creating a new closure via the arrow function
      // If you use this.refresh(), this will become the window object later in the stack
      setTimeout(() => {
        this.refresh();
      }, 0);
    }
  }

  /**
   * Attempts to make use of sessionStorage
   * @returns {boolean} with the result of whether it is available
   */
  isStorageAvailable(): boolean {
    return isStorageAvailable('sessionStorage');
  }

  /**
   * Reads the value of the specified item from session storage.
   * @param {string} name The name of the item to read.
   * @returns The value of the item, or undefined if no value is set.
   */
  get(name: string): string | null | undefined {
    const item = SessionStorageManifest.get(name);

    if (!item) {
      log.warn(
        `No item matching the name ${name} was found in the sessionStorage.yaml manifest. ` +
          `Reading the value of the item will work, but attempting to set the item ` +
          `will result in an error. If you're adding a new sessionStorage item, please visit ` +
          `go/cookies for more information!`,
      );
    }

    if (isStorageAvailable('sessionStorage')) {
      // This call to refresh() is not guaranteed to remove items before the
      // returning the item. What's important is making sure the storage will
      // eventually remove values that the user did not consent to give as
      // soon as possible.
      this.refresh();
      return sessionStorage.getItem(name);
    }

    return null;
  }

  /**
   * Attempts to write the specified value to session storage. This will only succeed if:
   *
   *   1. The item belongs to a necessary category, OR
   *   2. The user has set permissions which allow items of the category
   *      to which the item belongs, OR
   *   3. The enforcement mode is currently set to "open", indicating that
   *      the current user is not located within an enforcement zone.
   *
   * @param {string} name The name of the item to write.
   * @param {string} value The value to write for the item.
   * @returns A promise for a value which indicates whether the item was successfully set.
   */
  async set(name: string, value: string): Promise<boolean> {
    const item = SessionStorageManifest.get(name);

    if (!item) {
      log.error(
        `No item matching the name ${name} was found in the sessionStorage.yaml manifest. ` +
          `If you're adding a new item, please visit go/cookies for more information!`,
      );
      return false;
    }

    // If the item is in a category that isn't allowed for the user, block it.
    const isAllowed = await this.isCategoryAllowed(item.category);
    if (!isAllowed) return false;

    if (!isStorageAvailable('sessionStorage')) return false;

    sessionStorage.setItem(name, value);

    return true;
  }

  /**
   * Removes the specified item from session storage.
   * @param {string} name The name of the item to delete.
   * @returns True if the item was successfully set, otherwise false.
   */
  remove(name: string): boolean {
    const item = SessionStorageManifest.get(name);

    if (!item) {
      log.error(
        `No item matching the name ${name} was found in the sessionStorage.yaml manifest. ` +
          `If you're adding a new item, please visit go/cookies for more information!`,
      );
      return false;
    }

    if (!isStorageAvailable('sessionStorage')) return false;

    sessionStorage.removeItem(name);
    return true;
  }

  /**
   * This function checks the sessionStorage keys and sees if the user has
   * consented to each value. If not, remove the offending item.
   */
  async refresh() {
    Object.keys(sessionStorage).forEach((name) => {
      const item = SessionStorageManifest.get(name);

      // The existing logic implicitly considered values outside of the
      // manifest are categorized as necessary. See the get method warning for
      // more context.
      if (item && sessionStorage.getItem(name)) {
        const categoryAllowed = this.isCategoryAllowedMaybeSync(item.category);

        // If the item is in a category that isn't allowed for the user, remove it.
        if (categoryAllowed === false) {
          sessionStorage.removeItem(name);
        }

        if (categoryAllowed instanceof Promise) {
          categoryAllowed.then((value) => {
            if (!value) {
              sessionStorage.removeItem(name);
            }
          });
        }
      }
    });
  }
}
