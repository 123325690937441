import ReactDOM from 'react-dom';

import {RootLayer} from '@sail/ui';
import {Provider as ThemeProvider} from '@sail/theme-classic';

import {App} from 'src/App';

const reactRoot = document.getElementById('react-root') as HTMLElement;

const Index = () => (
  <ThemeProvider>
    <RootLayer>
      <App />
    </RootLayer>
  </ThemeProvider>
);

ReactDOM.render(<Index />, reactRoot);
