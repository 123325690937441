/* eslint-disable @typescript-eslint/no-explicit-any */
import {captureException} from '@sentry/browser';
import {Cookies} from '@stripe-internal/stripe-cookies';
import {mountInvisible} from '@stripe-internal/stripethirdparty';
import {InitializeGoogleTagManagerInput} from '@stripe-internal/stripethirdparty/lib/shared/GoogleTagManagerTypes';

const THIRD_PARTY_BASE_URL =
  'https://b.stripecdn.com/stripethirdparty-srv/assets/';
const GTM_KEY = 'GTM-NH493PHL';

// taken from @stripe-internal/stripethirdparty/types
export const GTM_INPUT_TAGS = {
  initialize: 'INITIALIZE_GOOGLE_TAG_MANAGER',
  checkLoaded: 'CHECK_GTM_LOADED',
  trackEvent: 'TRACK_EVENT',
  trackPageView: 'TRACK_PAGE_VIEW',
} as const;

/** Queue of events that came in while we're waiting for initialization. */
const queue: Array<{
  name: string;
  params: any;
}> = [];

type AnalyticsFunc = {
  (name: string, params: any): Promise<void>;
  globalAnalytics?: any;
};

const devLog = (message: string, v?: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(message, v);
  }
};

const gtmAvailable = async (proxy: any): Promise<boolean> => {
  const {isGTMLoaded} = await proxy.sendRequest({
    tag: GTM_INPUT_TAGS.checkLoaded,
  });
  return isGTMLoaded;
};

const asyncSleep = (intervalMillis: number) => {
  return new Promise(
    (resolve: (result: Promise<undefined> | undefined) => void) =>
      // eslint-disable-next-line no-restricted-globals, no-promise-executor-return
      setTimeout(resolve, intervalMillis),
  );
};

const waitForGtmAvailable = async (proxy: any): Promise<boolean> => {
  const retryIntervalMillis = 10;
  const timeoutMillis = 10000;
  const totalRetries = timeoutMillis / retryIntervalMillis; // 1000 (10s / 10ms)

  /* eslint-disable no-await-in-loop */
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < totalRetries; i++) {
    if (await gtmAvailable(proxy)) {
      return true;
    }
    await asyncSleep(retryIntervalMillis);
  }

  return false;
};

/**
 * Actual analytics function. Will be swapped out once initialization completes.
 */
let _analytics: AnalyticsFunc = async (
  name: string,
  params: Partial<Record<any, any>> | null | undefined = {},
): Promise<void> => {
  devLog('Queuing analytics event:', {name, params});
  queue.push({name, params});
};

export async function initializeAnalytics(): Promise<void> {
  const initializeInput: InitializeGoogleTagManagerInput = {
    tag: GTM_INPUT_TAGS.initialize,
    message: {apiKey: GTM_KEY},
    setupOptions: {
      dataLayerVariables: {
        url: window.location.href,
        title: document.title,
        referrer: document.referrer,
      },
    },
  };
  devLog('Initializing analytics', initializeInput);

  let proxy: any = null;
  let isGtmAvailable = false;
  proxy = mountInvisible({
    type: 'GoogleTagManager',
    baseURL: THIRD_PARTY_BASE_URL,
    onReady: (sendMessage: any) => {
      sendMessage(initializeInput);
    },
  });

  const cookies = new Cookies();
  const allowedCookieCategories = {
    advertising: await cookies.isCategoryAllowed('advertising'),
    functional: await cookies.isCategoryAllowed('functional'),
    statistics: await cookies.isCategoryAllowed('statistics'),
  };
  const allowedCookies = Object.entries(allowedCookieCategories)
    .filter(([_, allowed]) => allowed)
    .map(([key, _]) => key)
    .join(',');

  isGtmAvailable = await waitForGtmAvailable(proxy);
  devLog('Mounted GTM', {proxy, isGtmAvailable});

  if (isGtmAvailable) {
    const pageViewMessage = {
      tag: GTM_INPUT_TAGS.trackPageView,
      url: THIRD_PARTY_BASE_URL,
      allowedCookies,
    };
    proxy.sendMessage(pageViewMessage);
    devLog('Sent page view message', pageViewMessage);
  }

  // create real analytics function
  const realAnalyticsFunc: AnalyticsFunc = async (
    event: string,
    params: Record<string, unknown> = {},
  ): Promise<void> => {
    if (proxy && isGtmAvailable) {
      devLog('Sending analytics event:', {event, params});
      proxy.sendMessage({
        tag: GTM_INPUT_TAGS.trackEvent,
        name: event,
        allowedCookies,
        params,
      });
    }
  };

  // flush queued events while we were waiting for initialization
  // await asyncSleep(1000 /* 1s */);
  queue.map(({name, params}): Promise<void> => {
    devLog('Processing queued event', {name, params});
    return realAnalyticsFunc(name, params);
  });
  // clear queue
  queue.length = 0;

  // swap out analytics func
  _analytics = realAnalyticsFunc;
}

export default function analytics(
  name: string,
  params: Partial<Record<any, any>> | null | undefined = {},
): void {
  _analytics(name, params).catch((err) => captureException(err));
}
