import bgBG from 'translations/bg-BG.json';
import csCZ from 'translations/cs-CZ.json';
import daDK from 'translations/da-DK.json';
import deDE from 'translations/de-DE.json';
import elGR from 'translations/el-GR.json';
import enGB from 'translations/en-GB.json';
import es419 from 'translations/es-419.json';
import esES from 'translations/es-ES.json';
import etEE from 'translations/et-EE.json';
import fiFI from 'translations/fi-FI.json';
import filPH from 'translations/fil-PH.json';
import frCA from 'translations/fr-CA.json';
import frFR from 'translations/fr-FR.json';
import hrHR from 'translations/hr-HR.json';
import huHU from 'translations/hu-HU.json';
import idID from 'translations/id-ID.json';
import itIT from 'translations/it-IT.json';
import jaJP from 'translations/ja-JP.json';
import koKR from 'translations/ko-KR.json';
import ltLT from 'translations/lt-LT.json';
import lvLV from 'translations/lv-LV.json';
import msMY from 'translations/ms-MY.json';
import mtMT from 'translations/mt-MT.json';
import nbNO from 'translations/nb-NO.json';
import nlNL from 'translations/nl-NL.json';
import plPL from 'translations/pl-PL.json';
import ptBR from 'translations/pt-BR.json';
import ptPT from 'translations/pt-PT.json';
import roRO from 'translations/ro-RO.json';
import skSK from 'translations/sk-SK.json';
import slSI from 'translations/sl-SI.json';
import svSE from 'translations/sv-SE.json';
import thTH from 'translations/th-TH.json';
import trTR from 'translations/tr-TR.json';
import viVN from 'translations/vi-VN.json';
import zhHans from 'translations/zh-Hans.json';

export const DEFAULT_LANG = 'en';
export const DEFAULT_LOCALE = 'en-US';

export const AVAILABLE_LOCALES = {
  'bg-BG': bgBG,
  'cs-CZ': csCZ,
  'da-DK': daDK,
  'de-DE': deDE,
  'el-GR': elGR,
  'en-GB': enGB,
  'en-US': null,
  'es-419': es419,
  'es-ES': esES,
  'et-EE': etEE,
  'fi-FI': fiFI,
  'fil-PH': filPH,
  'fr-CA': frCA,
  'fr-FR': frFR,
  'hr-HR': hrHR,
  'hu-HU': huHU,
  'id-ID': idID,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'lt-LT': ltLT,
  'lv-LV': lvLV,
  'ms-MY': msMY,
  'mt-MT': mtMT,
  'nb-NO': nbNO,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  'pt-PT': ptPT,
  'ro-RO': roRO,
  'sk-SK': skSK,
  'sl-SI': slSI,
  'sv-SE': svSE,
  'th-TH': thTH,
  'tr-TR': trTR,
  'vi-VN': viVN,
  'zh-Hans': zhHans,
};

export type LocaleKey = keyof typeof AVAILABLE_LOCALES;

export const LOCALE_MAP: {
  [lang: string]: LocaleKey;
} = {
  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-US',
  es: 'es-ES',
  'es-MX': 'es-419',
  et: 'et-EE',
  fi: 'fi-FI',
  fil: 'fil-PH',
  fr: 'fr-FR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  id: 'id-ID',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  lv: 'lv-LV',
  lt: 'lt-LT',
  ms: 'ms-MY',
  mt: 'mt-MT',
  nb: 'nb-NO',
  nl: 'nl-NL',
  no: 'nb-NO',
  nn: 'nb-NO',
  pl: 'pl-PL',
  pt: 'pt-PT',
  sk: 'sk-SK',
  sl: 'sl-SI',
  sv: 'sv-SE',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
  zh: 'zh-Hans',
  'zh-CN': 'zh-Hans',
};

export const getLocale = (overrideLocale?: string | null): LocaleKey => {
  let stringLocale = '';

  // an override supercedes the default and the browser language -- it may have come in
  // as the `locale` query string parameter.
  if (overrideLocale) {
    stringLocale = overrideLocale;
  } else if (typeof window !== 'undefined' && window.navigator) {
    // use the browser language
    stringLocale =
      // @ts-expect-error - TS2339 - Property 'userLanguage' does not exist on type 'Navigator'.
      window.navigator.userLanguage ||
      window.navigator.language ||
      DEFAULT_LOCALE;
  }

  let locale: LocaleKey;
  if (!(stringLocale in AVAILABLE_LOCALES)) {
    // sometimes the language code is too specific or too general
    const shortLangCode = stringLocale.split(/[-_]/)[0];
    locale =
      LOCALE_MAP[stringLocale] || LOCALE_MAP[shortLangCode] || DEFAULT_LOCALE;
  } else {
    // $ExpectError -- suppress the type error: based on conditional logic, this string _must_ be in LocaleKey
    locale = stringLocale as LocaleKey;
  }

  return locale;
};

export const sanitizeLocale = (locale: LocaleKey): LocaleKey => {
  return locale in AVAILABLE_LOCALES ? locale : DEFAULT_LOCALE;
};

export const importLocaleJsons = async (locale: LocaleKey) => {
  if (locale === DEFAULT_LOCALE) {
    return {};
  }

  return AVAILABLE_LOCALES[locale];
};
