{
  "standalone_onramp.AddThisToYourSite": "Sie möchten dies zu Ihrer Website hinzufügen?",
  "standalone_onramp.ChooseBetween": "Wählen Sie zwischen Kreditkarte, Debitkarte und Banküberweisung.",
  "standalone_onramp.CookieSettings": "Cookie-Einstellungen",
  "standalone_onramp.FastAndSecure": "Schnell und sicher",
  "standalone_onramp.FlexibleWaysToPay": "Flexible Zahlungsmöglichkeiten",
  "standalone_onramp.GetStarted": "Loslegen",
  "standalone_onramp.Help": "Hilfe",
  "standalone_onramp.InstantlyBuyCrypto": "Sofort Krypto kaufen",
  "standalone_onramp.LearnMore": "Mehr erfahren",
  "standalone_onramp.LowFees": "Geringe Gebühren",
  "standalone_onramp.PayFaster": "Schneller bezahlen mit einer Datenverschlüsselung, die die höchsten verfügbaren PCI-Standards erfüllt.",
  "standalone_onramp.Privacy": "Datenschutz",
  "standalone_onramp.SaveUpTo": "Sparen Sie bis zu 50 % Transaktionsgebühren beim Bezahlvorgang.",
  "standalone_onramp.Terms": "AGB",
  "standalone_onramp.ErrorMessage": "Beim Laden der Anwendung ist ein Fehler aufgetreten. Bitte versuchen Sie es in ein paar Minuten erneut."
}