import {useEffect, useState} from 'react';
import {Spinner, view, css} from '@sail/ui';

import {ErrorMessage} from 'src/components/ErrorMessage';
import {useScript} from 'src/hooks/useScript';
import {CRYPTO_ONRAMP_JS_URL} from 'src/utils/helpers';
import type {StripeOnramp} from '@stripe/crypto';
import analytics from 'src/utils/analytics';

interface Props {
  integratingMerchantPublishableKey: string;
  onrampSessionClientSecret: string;
  showFullView: boolean;
}

const CryptoOnrampSession = ({
  integratingMerchantPublishableKey,
  onrampSessionClientSecret,
  showFullView,
}: Props) => {
  const [mountCalled, setMountCalled] = useState<boolean>(false);
  const onrampScriptStatus = useScript(CRYPTO_ONRAMP_JS_URL);

  useEffect(() => {
    if (onrampScriptStatus === 'ready' && window.StripeOnramp) {
      const stripeOnramp: StripeOnramp = window.StripeOnramp(
        integratingMerchantPublishableKey,
      );
      setMountCalled(true);
      stripeOnramp
        .createSession({clientSecret: onrampSessionClientSecret})
        .addEventListener(
          'onramp_session_updated',
          ({type, payload: {session}}) => {
            analytics(type, {
              status: session.status,
              livemode: session.livemode,
              source_currency: session.quote?.source_currency?.asset_code,
              destination_currency:
                session.quote?.destination_currency?.asset_code,
              destination_network:
                session.quote?.destination_currency?.currency_network,
            });
          },
        )
        .mount('onramp');
    }
  }, [
    integratingMerchantPublishableKey,
    onrampSessionClientSecret,
    onrampScriptStatus,
  ]);

  const margin = showFullView ? '0px' : '5px';

  return (
    <view.div
      css={{
        width: css.value('100%'),
        height: css.value('100%'),
        textAlign: css.value('center'),
        stack: 'y',
        alignY: 'center',
        overflow: css.value('hidden'),
      }}
    >
      <view.div
        id="onramp"
        css={{
          height: css.value('100%'),
          marginLeft: css.value(`-${margin}`),
          marginRight: css.value(`-${margin}`),
          marginTop: css.value(`-${margin}`),
          '& iframe': {
            height: css.value(`calc(100% + ${margin})`),
            maxHeight: css.value(`calc(100% + ${margin}) !important`),
          },
        }}
        hidden={!(mountCalled && onrampScriptStatus !== 'error')}
      />
      {!mountCalled && onrampScriptStatus !== 'error' ? (
        <Spinner size="large" />
      ) : null}
      {onrampScriptStatus === 'error' ? <ErrorMessage /> : null}
    </view.div>
  );
};

export default CryptoOnrampSession;
