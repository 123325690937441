import {parseString} from 'set-cookie-parser';
import {isBrowser, log} from '../util';
import {Cookies} from './Cookies';

let cookieProperty: PropertyDescriptor | null | undefined = null;
let lastSetPromise: Promise<boolean> | null = null;

/**
 * Used to patch document.cookie to intercept attempts to set cookies directly.
 * @private
 */
export const MonkeyPatcher = {
  /**
   * Patches the document.cookie property to trap all sets and route them to the specified
   * Cookies instance.
   * @param {Cookies} cookies The Cookies instance to which set operations should be routed.
   */
  patchDocumentCookie: (cookies: Cookies) => {
    if (!isBrowser) {
      log.error(`You cannot patch document.cookie if you aren't in a browser.`);
      return false;
    }

    if (!cookieProperty) {
      cookieProperty = Object.getOwnPropertyDescriptor(
        Document.prototype,
        'cookie',
      );
    }

    if (!cookieProperty || !cookieProperty.get || !cookieProperty.set) {
      return false;
    }

    const getFunction = cookieProperty.get;
    const setFunction = cookieProperty.set;

    Object.defineProperty(document, 'cookie', {
      configurable: true,
      get(...args) {
        // Since permissions don't affect reads, we don't trap get() operations. If we did,
        // we could show warnings in dev for unknown cookies, but there isn't really a
        // clear way to avoid infinite loops.
        return getFunction.apply(this, args);
      },
      set(val) {
        // Sometimes people try to clear cookies by setting document.cookie to an empty string.
        // That doesn't actually do anything, but there's no actual set operation to trap,
        // so we'll let them try. :)
        if (!val || val.length === 0) {
          return setFunction.apply(this, [val]);
        }

        // NB: We don't make an attempt to extract the expires/max-age attributes here,
        // which means trapped cookies will always be set with the lifetime read from
        // the manifest. This is good for compliance purposes, because it means third
        // parties can't set expirations longer than we're allowing. To exert real
        // control over the expiration, call stripe-cookies directly.

        // `allowed` is technically not one of the fields that are on the
        // return type of parseString, but since there's a bunch of bespoke
        // logic around `allowed` we'll ignore the warning until further
        // testing.

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore @rollup/plugin-typescript TS2339
        const {name, value, domain, allowed} = parseString(val);

        if (allowed === 'true') {
          // If the cookie has an "allowed" attribute, it means that the set() operation
          // has already been checked by the Cookies instance. Don't send it back again.
          setFunction.apply(this, [val]);
        } else {
          // Otherwise, we need to route the set() operation through the Cookies
          // instance to ensure the cookie is allowed to be set. We store the Promise
          // that's returned in case we want to access it during testing.
          lastSetPromise = cookies.set(name, value, {domain});
        }
      },
    });

    return true;
  },

  /**
   * Removes a previously-applied patch on document.cookie.
   */
  restoreDocumentCookie: () => {
    if (!cookieProperty) {
      log.error(`The document.cookie property has not been patched`);
      return;
    }

    Object.defineProperty(document, 'cookie', cookieProperty);
  },

  /**
   * Returns the Promise that was returned from the most-recent call to Cookies.set().
   * Tests which set cookies directly using the document.cookie property can use this
   * to wait until the cookie value is actually set before asserting.
   */
  getLastSetPromise: () => {
    return lastSetPromise;
  },
} as const;
