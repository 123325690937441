import {Cookie} from './Cookie';
import COOKIES from '../../../cookies.yaml';
import NEXT_COOKIES from '../../../cookies-next.yaml';

let inexactCookies: Array<Cookie> | null = null;

/**
 * Contains information about all known cookies, loaded from pay-server/cookies/cookies{-next}.yaml.
 * Only cookies which have entries in the manifest are allowed to be set.
 * @private
 */
export const CookieManifest = {
  /**
   * Gets the Cookie definintion for the specified cookie from the manifest.
   * @param {string} name The name of the cookie to retrieve.
   * @returns The matching Cookie, or null if none was found.
   */
  get(name: string): Cookie | null {
    // First, try to match the cookie name exactly.
    if (COOKIES[name]) {
      return new Cookie(name, COOKIES[name] as Cookie);
    }
    if (NEXT_COOKIES[name]) {
      return new Cookie(name, NEXT_COOKIES[name] as Cookie);
    }

    // If we didn't find an exact match, try to match by pattern instead.
    // Lazily initialize so that pulling in the dependency doesn’t blow up
    // IE 11 at script evaluation time, since Object.entries is not supported.
    if (!inexactCookies) {
      inexactCookies = [
        ...Object.entries(COOKIES),
        ...Object.entries(NEXT_COOKIES),
      ]
        .map(([n, value]) => new Cookie(n, value as Cookie))
        .filter((c) => c.inexact);
    }
    for (const cookie of inexactCookies) {
      if (cookie.isMatch(name)) return cookie;
    }

    return null;
  },
} as const;
