import {LocalStorageItem} from './LocalStorageItem';
import MANIFEST from '../../../localStorage.yaml';

const items = Object.keys(MANIFEST).reduce<{
  [manifestKeys: string]: LocalStorageItem;
}>((hash, name) => {
  const manifestValue = MANIFEST[name] as LocalStorageItem;
  hash[name] = new LocalStorageItem(name, manifestValue);
  return hash;
}, {});

const inexactItems = Object.keys(items)
  .filter((name) => items[name].inexact)
  .map((name) => items[name]);

/**
 * Contains information about all known local storage items, loaded from
 * pay-server/cookies/localStorage.yaml. Only items which have entries in the
 * manifest are allowed to be set.
 * @private
 */
export const LocalStorageManifest = {
  /**
   * Gets the LocalStorageItem definition for the specified item from the manifest.
   * @param {string} name The name of the item to retrieve.
   * @returns The matching LocalStorageItem, or null if no match was found.
   */
  get(name: string): LocalStorageItem | null {
    // First, try to match the item's name exactly.
    if (items[name]) return items[name];

    // If we didn't find an exact match, try to match by pattern instead.
    for (const item of inexactItems) {
      if (item.isMatch(name)) return item;
    }

    return null;
  },
} as const;
