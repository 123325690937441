import {defineMessages} from 'react-intl';

const messages = defineMessages({
  instantlyBuyCrypto: {
    id: 'standalone_onramp.InstantlyBuyCrypto',
    defaultMessage: 'Instantly buy crypto',
  },
  fastAndSecure: {
    id: 'standalone_onramp.FastAndSecure',
    defaultMessage: 'Fast and secure',
  },
  payFaster: {
    id: 'standalone_onramp.PayFaster',
    defaultMessage:
      'Pay faster with data encryption that meet the highest PCI standards available.',
  },
  flexibleWaysToPay: {
    id: 'standalone_onramp.FlexibleWaysToPay',
    defaultMessage: 'Flexible ways to pay',
  },
  chooseBetween: {
    id: 'standalone_onramp.ChooseBetween',
    defaultMessage: 'Choose between credit, debit, and bank.',
  },
  lowFees: {
    id: 'standalone_onramp.LowFees',
    defaultMessage: 'Low fees',
  },
  saveUpTo: {
    id: 'standalone_onramp.SaveUpTo',
    defaultMessage: 'Save up to 50% on fees at checkout.',
  },
  addThisToYourSite: {
    id: 'standalone_onramp.AddThisToYourSite',
    defaultMessage: 'Interested in adding this to your site?',
  },
  getStarted: {
    id: 'standalone_onramp.GetStarted',
    defaultMessage: 'Get started',
  },
  help: {
    id: 'standalone_onramp.Help',
    defaultMessage: 'Help',
  },
  terms: {
    id: 'standalone_onramp.Terms',
    defaultMessage: 'Terms',
  },
  privacy: {
    id: 'standalone_onramp.Privacy',
    defaultMessage: 'Privacy',
  },
  cookieSettings: {
    id: 'standalone_onramp.CookieSettings',
    defaultMessage: 'Cookie Settings',
  },
  learnMore: {
    id: 'standalone_onramp.LearnMore',
    defaultMessage: 'Learn more',
  },
  errorMessage: {
    id: 'standalone_onramp.ErrorMessage',
    defaultMessage:
      'An error occurred while loading the application. Please try again in a few minutes.',
  },
});
export default messages;
