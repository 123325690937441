import {Category, CookieLifetime, CookieSameSiteSetting} from '../types';
import {isBrowser, isDomainMatch} from '../util';

/**
 * Represents a known cookie read from the {CookieManifest}.
 * @param {String} name The name of the cookie.
 * @param {$Shape<Cookie>} attrs Attributes read from the entry in the cookie manifest.
 * @private
 */
export class Cookie {
  _regexp: RegExp | null | undefined;

  /**
   * The name of the cookie. For inexact cookies, this can be a pattern using asterisks as placeholders
   * for any characters.
   */
  name: string;

  /**
   * If true, allow name to represent a pattern instead of a direct match.
   */
  inexact: boolean;

  /**
   * The cookie's category (preferences, advertising, etc.)
   */
  category: Category;

  /**
   * The maximum number of seconds after which the cookie expires, or "session" if the cookie is not persistent.
   */
  lifetime: CookieLifetime;

  /**
   * A list of domains on which the cookie is allowed to be set. This can also include the value "request",
   * which indicates that the cookie can be set on the domain of the current request.
   */
  domains: string[];

  /**
   * The HttpOnly setting for the cookie. Since HttpOnly cookies can't be set via JavaScript, this is just
   * used for showing errors.
   */
  httpOnly: boolean;

  /**
   * The SameSite setting for the cookie.
   */
  sameSite: CookieSameSiteSetting;

  /**
   * Indicates that the cookie can only be read via HTTPS.
   */
  secure: boolean;

  constructor(name: string, attrs: Cookie) {
    this.name = name;
    this.category = attrs.category;
    this.lifetime = attrs.lifetime;
    this.domains = attrs.domains;
    this.secure = !(attrs.secure === false);
    this.httpOnly = !(attrs.httpOnly === false);
    this.sameSite = attrs.sameSite || 'Lax';

    if (this.name.indexOf('*') >= 0) {
      this.inexact = true;
      this._regexp = new RegExp(name.replace('*', '.+'));
    } else {
      this.inexact = false;
    }
  }

  /**
   * Determines whether the given cookie name matches this cookie's name. Performs an inexact
   * match if this cookie supports inexact (pattern-based) matching.
   * @param {String} str The string to match.
   */
  isMatch(str: string) {
    return this.name === str || (this._regexp && this._regexp.test(str));
  }

  /**
   * Given the settings from the manifest and the domain passed in at the callsite, determines
   * on which domain the cookie should be set.
   * @param {string} callsiteDomain The domain requested at the callsite.
   * @param {Map} overrides A map of domain overrides to apply.
   */
  resolveDomain(
    callsiteDomain: string | null | undefined,
    overrides: {
      [domain: string]: string;
    },
  ): string | undefined {
    // Domains starting with the prefix __Host- should not have a domain.
    // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/06-Session_Management_Testing/02-Testing_for_Cookies_Attributes#host-prefix
    if (this.name.startsWith('__Host-')) {
      return undefined;
    }

    // Apply any domain overrides. This is used to rewrite the domains for cookies during testing.
    let domains: string[] = [];

    if (this.domains) {
      domains = this.domains.map((domain) => overrides[domain] || domain);
    }

    let desiredDomain = callsiteDomain;

    // An unset domain name in the browser defaults to the current host
    if (!desiredDomain && isBrowser) {
      desiredDomain = document.location.host;
    }

    // Allow any cookie to be set on devbox domains.
    // TODO (nkohari): Could this be implemented using overrides instead?
    if (desiredDomain && desiredDomain.match('dev.stripe.me')) {
      return '.dev.stripe.me';
    }

    // The value of the domain option must match a domain in the cookie's domains array.
    if (
      desiredDomain &&
      !domains.some((d) => isDomainMatch(desiredDomain as string, d))
    ) {
      throw new Error(
        `The ${this.name} cookie is not allowed to be set on the ${desiredDomain} domain.`,
      );
    }

    return desiredDomain || domains[0];
  }

  /**
   * Given the settings from the manifest and the lifetime passed in at the callsite, determines
   * the expiry that should be set on the cookie.
   * @param {number} desiredLifetime The lifetime (in seconds) requested at the callsite.
   * @returns The date at which the cookie will expire, or undefined if it is a
   *  session cookie. Note that the undefined return value is determined by the
   *  js-cookie CookieAttributes type for expires.
   */
  resolveExpiry(desiredLifetime?: number | null): Date | undefined {
    if (this.lifetime === 'forever') {
      return undefined;
    }

    if (this.lifetime === 'session') {
      // Session cookies cannot have a lifetime set.
      if (desiredLifetime) {
        throw new Error(
          `The ${this.name} cookie cannot have a lifetime, because it is a session cookie.`,
        );
      }

      // If the cookie is a session cookie, it has no expiration, and it will
      // expire when the user closes their browser.
      return undefined;
    }

    let lifetime = this.lifetime || 0;

    if (desiredLifetime) {
      // The lifetime option cannot be greater than the cookie's lifetime in the manifest.
      if (lifetime < desiredLifetime) {
        throw new Error(
          `The ${this.name} cookie has a maximum lifetime of ${this.lifetime} seconds.`,
        );
      }

      lifetime = desiredLifetime;
    }

    // The js-cookie library expects either a Date or a number of days from now. Since
    // some of our cookies have lifetimes less than a single day, we use Dates.
    return new Date(Date.now() + lifetime * 1000);
  }
}
