{
  "standalone_onramp.AddThisToYourSite": "Bạn muốn thêm vào trang web của mình?",
  "standalone_onramp.ChooseBetween": "Chọn trong số tín dụng, ghi nợ và ngân hàng.",
  "standalone_onramp.CookieSettings": "Cài đặt Cookie",
  "standalone_onramp.FastAndSecure": "Nhanh chóng và bảo mật",
  "standalone_onramp.FlexibleWaysToPay": "Các phương thức thanh toán linh hoạt",
  "standalone_onramp.GetStarted": "Bắt đầu",
  "standalone_onramp.Help": "Hỗ trợ",
  "standalone_onramp.InstantlyBuyCrypto": "Mua tiền điện tử tức thì",
  "standalone_onramp.LearnMore": "Tìm hiểu thêm",
  "standalone_onramp.LowFees": "Chi phí thấp",
  "standalone_onramp.PayFaster": "Thanh toán nhanh hơn bằng mã hóa dữ liệu đáp ứng tiêu chuẩn PCI cao nhất hiện có.",
  "standalone_onramp.Privacy": "Quyền riêng tư",
  "standalone_onramp.SaveUpTo": "Tiết kiệm tới 50% chi phí khi thanh toán.",
  "standalone_onramp.Terms": "Điều khoản",
  "standalone_onramp.ErrorMessage": "Đã xảy ra lỗi khi tải ứng dụng. Vui lòng thử lại trong vài phút."
}