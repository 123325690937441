import {log} from './util';
import {Permissions} from './Permissions';
import {PermissionsChangedCallback} from './types';

const POLLING_INTERVAL_MS = 100;

type GetPermissionsFunc = () => Permissions | null;

/**
 * Allows callback functions to request to be informed when permissions change.
 * Rather than a simple callback built into the setPermissions() function of
 * Cookies, we poll for changes to the cookie itself to detect permissions
 * being changed in a different browser tab.
 * @param {GetPermissionsFunc} getPermissions The function to call to read permissions.
 * @private
 */
export class PermissionsWatcher {
  _getPermissions: GetPermissionsFunc;

  _callbacks: PermissionsChangedCallback[];

  _interval: ReturnType<typeof setInterval> | null | undefined;

  constructor(getPermissions: GetPermissionsFunc) {
    this._getPermissions = getPermissions;
    this._callbacks = [];
  }

  /**
   * Registers a callback function which will be called whenever permissions change.
   * @param {PermissionsChangedCallback} callback The function which will be called.
   */
  addCallback(callback: PermissionsChangedCallback): void {
    const index = this._callbacks.indexOf(callback);

    if (index >= 0) {
      log.error(
        `PermissionsWatcher.addCallback() was called for a function that was already ` +
          `registered with the PermissionsWatcher.`,
      );
      return;
    }

    this._callbacks.push(callback);

    // Begin polling for changes if we aren't already.
    if (!this._interval) {
      this._pollForPermissionsChanges();
    }
  }

  /**
   * Removes the specified callback function, causing it to no longer be called when
   * permissions change.
   * @param {PermissionsChangedCallback} callback The callback function to remove.
   */
  removeCallback(callback: PermissionsChangedCallback): void {
    const index = this._callbacks.indexOf(callback);

    if (index < 0) {
      log.error(
        `PermissionsWatcher.removeCallback() was called for a function that wasn't ` +
          `registered with the PermissionsWatcher.`,
      );
      return;
    }

    // Remove the callback from the list.
    this._callbacks.splice(index, 1);

    // Stop polling if we no longer have any callbacks.
    if (this._interval && this._callbacks.length === 0) {
      clearInterval(this._interval);
      this._interval = null;
    }
  }

  /**
   * Polls the value of the permissions cookie. If changes have occurred, calls all
   * registered callbacks with a Permissions object representing the new permissions.
   * @private
   */
  _pollForPermissionsChanges() {
    let previous = this._getPermissions();

    const checkForChanges = () => {
      const current = this._getPermissions();

      // It's not currently possible to clear permissions entirely, so if permissions
      // don't exist, we know they can't have changed.
      if (current === null) return;

      // Check if permissions were not previously set, but now have been set.
      const permissionsCreated = previous === null && current !== null;

      // Check if permissions were previously set, and now have been changed.
      const permissionsChanged =
        previous && current && previous.timestamp !== current.timestamp;

      // If permissions have been changed, pass them to all of the registered callbacks.
      if (permissionsCreated || permissionsChanged) {
        this._callbacks.forEach((func) => func(current));
      }

      previous = current;
    };

    this._interval = setInterval(checkForChanges, POLLING_INTERVAL_MS);
  }
}
