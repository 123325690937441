{
  "standalone_onramp.AddThisToYourSite": "サイトへの導入をご希望の場合",
  "standalone_onramp.ChooseBetween": "クレジット、デビット、銀行から選択します。",
  "standalone_onramp.CookieSettings": "Cookie 設定",
  "standalone_onramp.FastAndSecure": "スピーディかつ安全",
  "standalone_onramp.FlexibleWaysToPay": "柔軟な支払い方法",
  "standalone_onramp.GetStarted": "始める",
  "standalone_onramp.Help": "ヘルプ",
  "standalone_onramp.InstantlyBuyCrypto": "仮想通貨をすぐに購入",
  "standalone_onramp.LearnMore": "もっと知る",
  "standalone_onramp.LowFees": "手数料が安価",
  "standalone_onramp.PayFaster": "利用可能な最高レベルの PCI 基準に適合するデータの暗号化機能を適用してスピーディーに支払います。",
  "standalone_onramp.Privacy": "プライバシー",
  "standalone_onramp.SaveUpTo": "購入時に手数料を最大 50% 節約できます。",
  "standalone_onramp.Terms": "規約",
  "standalone_onramp.ErrorMessage": "アプリケーションの読み込み中にエラーが発生しました。数分後にもう一度お試しください。"
}