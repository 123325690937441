/**
 * Determines whether a given cookie domain matches the current hostname.
 * This algorithm is described by RFC 6265, and is designed to match the
 * one used by browsers. Browsers silently ignore cookies which don't
 * domain match for the current hostname, which can be INCREDIBLY confusing
 * during development. We try to catch these cases and warn that the cookies
 * will be ignored.
 */
export const isDomainMatch = (hostname: string, domain: string | undefined) => {
  // Cookie domains can be undefined if they're prefixed to __Host-. In the
  // negative case, the domain should never match.
  if (!domain) return false;

  // If the domain is an exact match, then it's an exact match. :)
  if (hostname === domain) return true;

  // If the domain is empty or doesn't begin with a dot, it's not a match.
  if (domain.length === 0 || domain[0] !== '.') return false;

  // If the domain is equal to the hostname with a leading dot, it's a match.
  if (domain === `.${hostname}`) return true;

  // If the domain has a leading dot and is a suffix of the hostname, it's a match.
  return hostname.length > domain.length && hostname.endsWith(domain);
};
