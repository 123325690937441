{
  "standalone_onramp.AddThisToYourSite": "Er du interessert i å legge dette til på nettstedet ditt?",
  "standalone_onramp.ChooseBetween": "Velg mellom kreditt, debet og bank.",
  "standalone_onramp.CookieSettings": "Innstillinger for informasjonskapsler",
  "standalone_onramp.FastAndSecure": "Raskt og sikkert",
  "standalone_onramp.FlexibleWaysToPay": "Fleksbile måter å betale på",
  "standalone_onramp.GetStarted": "Kom i gang",
  "standalone_onramp.Help": "Hjelp",
  "standalone_onramp.InstantlyBuyCrypto": "Kjøp krytpovaluta umiddelbart",
  "standalone_onramp.LearnMore": "Finn ut mer",
  "standalone_onramp.LowFees": "Lave gebyrer",
  "standalone_onramp.PayFaster": "Betal raskere med datakryptering som oppfyller de høyeste PCi-standardene som er tilgjengelige.",
  "standalone_onramp.Privacy": "Personvern",
  "standalone_onramp.SaveUpTo": "Spar opptil 50 % av gebyrene i kassen.",
  "standalone_onramp.Terms": "Vilkår",
  "standalone_onramp.ErrorMessage": "Det oppstod en feil under innlasting av applikasjonen. Prøv igjen om noen minutter."
}