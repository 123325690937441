import {isProduction} from './isProduction';

// The any[] type is the same type as the console.error/warn type,
// so we're just carrying that forward to this helper method.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const error = (message: string, ...args: any[]) => {
  if (!isProduction) {
    console.error(message, ...args); // eslint-disable-line no-console
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const warn = (message: string, ...args: any[]) => {
  if (!isProduction) {
    console.warn(message, ...args); // eslint-disable-line no-console
  }
};

export const log = {warn, error} as const;
