{
  "standalone_onramp.AddThisToYourSite": "Souhaitez-vous l'ajouter à votre site ?",
  "standalone_onramp.ChooseBetween": "Choisissez entre carte de crédit, carte de débit et compte bancaire.",
  "standalone_onramp.CookieSettings": "Paramètres des cookies",
  "standalone_onramp.FastAndSecure": "Rapide et sécurisé",
  "standalone_onramp.FlexibleWaysToPay": "Moyens de paiement flexibles",
  "standalone_onramp.GetStarted": "Démarrer",
  "standalone_onramp.Help": "Aide",
  "standalone_onramp.InstantlyBuyCrypto": "Achetez des cryptomonnaies en un instant",
  "standalone_onramp.LearnMore": "En savoir plus",
  "standalone_onramp.LowFees": "Frais réduits",
  "standalone_onramp.PayFaster": "Effectuez vos paiements plus rapidement grâce au chiffrement des données qui répond aux normes PCI les plus strictes.",
  "standalone_onramp.Privacy": "Confidentialité",
  "standalone_onramp.SaveUpTo": "Économisez jusqu'à 50 % sur les frais lors du paiement.",
  "standalone_onramp.Terms": "Conditions",
  "standalone_onramp.ErrorMessage": "Une erreur s'est produite lors du chargement de l'application. Veuillez réessayer dans quelques minutes."
}