{
  "standalone_onramp.AddThisToYourSite": "Vēlaties to pievienot savai vietnei?",
  "standalone_onramp.ChooseBetween": "Izvēlieties starp kredītkarti, debetkarti un banku.",
  "standalone_onramp.CookieSettings": "Sīkdatņu iestatījumi",
  "standalone_onramp.FastAndSecure": "Ātri un droši",
  "standalone_onramp.FlexibleWaysToPay": "Elastīgi maksāšanas veidi",
  "standalone_onramp.GetStarted": "Sākt izmantot",
  "standalone_onramp.Help": "Palīdzība",
  "standalone_onramp.InstantlyBuyCrypto": "Tūlītēji pērciet kriptovalūtu",
  "standalone_onramp.LearnMore": "Uzziniet vairāk",
  "standalone_onramp.LowFees": "Zemas maksas",
  "standalone_onramp.PayFaster": "Maksājiet ātrāk ar datu šifrēšanu, kas atbilst augstākajiem pieejamajiem PCI standartiem.",
  "standalone_onramp.Privacy": "Konfidencialitāte",
  "standalone_onramp.SaveUpTo": "Norēķināšanās laikā ietaupiet līdz 50 % par maksām.",
  "standalone_onramp.Terms": "Noteikumi",
  "standalone_onramp.ErrorMessage": "Ielādējot jūsu pieteikumu, radās kļūda. Lūdzu, mēģiniet vēlreiz pēc dažām minūtēm."
}