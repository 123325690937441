// If 'strict' mode is enabled, any usage of window will throw an undefined
// ReferenceError. The try block ensures the lack of window will not cause
// an uncaught exception.
function isWindowDefined() {
  try {
    return !!window;
  } catch (error) {
    return false;
  }
}

export const isBrowser =
  isWindowDefined() &&
  typeof window === 'object' &&
  typeof document === 'object' &&
  document.nodeType === 9;
