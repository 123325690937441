import React, {ReactNode, useState} from 'react';
import {IntlProvider} from 'react-intl';

import {LocaleKey, getLocale, importLocaleJsons} from 'src/utils/intl';
import messages from 'src/utils/messages';

const DEFAULT_MESSAGES: {[key: string]: string} = Object.entries(
  messages,
).reduce((acc, [_, {id, defaultMessage}]) => {
  return {
    ...acc,
    [id]: defaultMessage,
  };
}, {});

export const LocalizationWrapper = ({children}: {children: ReactNode}) => {
  const [locale, setLocale] = useState<LocaleKey>('en-US');
  const [appMessages, setAppMessages] = useState<
    Partial<Record<LocaleKey, {[key: string]: string}>>
  >({'en-US': DEFAULT_MESSAGES});

  const evaluatedLocale = getLocale();

  React.useEffect(() => {
    if (evaluatedLocale !== locale) {
      importLocaleJsons(evaluatedLocale).then((loadedMessages) => {
        setLocale(evaluatedLocale);
        setAppMessages({
          ...appMessages,
          [evaluatedLocale]: loadedMessages,
        });
      });
    }
  }, [appMessages, evaluatedLocale, locale]);

  return (
    <IntlProvider locale={locale} messages={appMessages[locale]}>
      {children}
    </IntlProvider>
  );
};
