{
  "standalone_onramp.AddThisToYourSite": "Interesseret i at føje dette til dit websted?",
  "standalone_onramp.ChooseBetween": "Vælg mellem kredit, debet og bank.",
  "standalone_onramp.CookieSettings": "Cookie-indstillinger",
  "standalone_onramp.FastAndSecure": "Hurtigt og sikkert",
  "standalone_onramp.FlexibleWaysToPay": "Fleksible måder at betale på",
  "standalone_onramp.GetStarted": "Kom i gang",
  "standalone_onramp.Help": "Hjælp",
  "standalone_onramp.InstantlyBuyCrypto": "Køb kryptovaluta med det samme",
  "standalone_onramp.LearnMore": "Få mere at vide",
  "standalone_onramp.LowFees": "Lave gebyrer",
  "standalone_onramp.PayFaster": "Betal hurtigere med datakryptering, der overholder de højeste tilgængelige PCI-standarder.",
  "standalone_onramp.Privacy": "Privatliv",
  "standalone_onramp.SaveUpTo": "Spar på til 50 % i gebyrer ved betaling.",
  "standalone_onramp.Terms": "Vilkår",
  "standalone_onramp.ErrorMessage": "Der opstod en fejl under indlæsning af programmet. Prøv igen om nogle få minutter."
}