{
  "standalone_onramp.AddThisToYourSite": "Interesadong idagdag ito sa iyong site?",
  "standalone_onramp.ChooseBetween": "Pumili sa pagitan ng credit, debit, at bangko",
  "standalone_onramp.CookieSettings": "Mga setting ng cookie",
  "standalone_onramp.FastAndSecure": "Mabilis at ligtas",
  "standalone_onramp.FlexibleWaysToPay": "Mga flexible na paraan para magbayad",
  "standalone_onramp.GetStarted": "Magsimula",
  "standalone_onramp.Help": "Tulong",
  "standalone_onramp.InstantlyBuyCrypto": "Agarang bumili ng crypto",
  "standalone_onramp.LearnMore": "Alamin ang higit pa",
  "standalone_onramp.LowFees": "Mababang mga bayarin",
  "standalone_onramp.PayFaster": "Magbayad nang mas mabilis sa data encryption na nakatutugon sa magagamit na pinakamatataas na pamantayan ng PCI.",
  "standalone_onramp.Privacy": "Pagkapribado",
  "standalone_onramp.SaveUpTo": "Makatipid ng hanggang 50% sa nga bayarin sa checkout.",
  "standalone_onramp.Terms": "Mga Tuntunin",
  "standalone_onramp.ErrorMessage": "May nangyaring error habang nilo-load ang application. Pakisubukan muli sa ilang minuto."
}