import {Categories} from './Categories';
import {Permissions} from './Permissions';
import {PermissionsWatcher} from './PermissionsWatcher';
import {resolveEnforcementMode} from './resolveEnforcementMode';

import {Category, EnforcementMode, PermissionsChangedCallback} from './types';

import {CookiesConfig} from './cookies/Cookies';
import {LocalStorageConfig} from './localStorage/LocalStorage';
import {SessionStorageConfig} from './sessionStorage/SessionStorage';

/**
 * Provides a facade over static methods so users can call Cookies.getPermissions(),
 * LocalStorage.getPermissions(), or SessionStorage.getPermissions() rather than
 * needing to deal with various types.
 */
export class PermissionsAwareStorageManager {
  /** @private */
  _watcher: PermissionsWatcher;

  /** @private */
  private _enforcementModeValue:
    | Promise<EnforcementMode>
    | EnforcementMode
    | null
    | undefined;

  constructor(
    config: CookiesConfig | LocalStorageConfig | SessionStorageConfig,
  ) {
    this._watcher = new PermissionsWatcher(() => Permissions.get());

    if (config.enforcementMode) {
      this._enforcementModeValue = config.enforcementMode;
    }
  }

  /**
   * Gets the current enforcement mode. If the mode isn't already known, this function
   * will make a fetch() call to an API endpoint to resolve the enforcement mode we
   * should use for the current user.
   * @returns A promise for the current user's enforcement mode.
   */
  async getEnforcementMode(): Promise<EnforcementMode> {
    return this.getEnforcementModeMaybeSync();
  }

  /**
   * Like "getEnforcementMode" but may return a value instead of a promise if the value is already resolved.
   */
  getEnforcementModeMaybeSync(): EnforcementMode | Promise<EnforcementMode> {
    if (!this._enforcementModeValue) {
      const enforcementModePromise = resolveEnforcementMode();

      enforcementModePromise.then((value) => {
        this._enforcementModeValue = value;
      });

      this._enforcementModeValue = enforcementModePromise;
    }

    return this._enforcementModeValue;
  }

  /**
   * Gets the user's current permissions set, if one has been set.
   * @returns A Permissions instance representing the user's permissions.
   */
  getPermissions(): Permissions | null {
    return Permissions.get();
  }

  /**
   * Sets the specified permissions for the user by making fetch() calls to the cookie
   * permissions API endpoints.
   * @param {Map} changes The permissions to change.
   * @returns A promise for a Permissions instance representing the new permissions.
   */
  async setPermissions(
    changes: Partial<Record<Category, boolean>>,
  ): Promise<Permissions | null> {
    return Permissions.set(changes);
  }

  /**
   * Set permissions for the user, allowing all cookies and local / session storage items.
   * @returns A promise for a Permissions instance representing the new permissions.
   */
  async allowAll(): Promise<Permissions | null> {
    return Permissions.allowAll();
  }

  /**
   * Set permissions for the user, blocking all unnecessary cookies and local / session storage items.
   * @returns A promise for a Permissions instance representing the new permissions.
   */
  async rejectAll(): Promise<Permissions | null> {
    return Permissions.rejectAll();
  }

  /**
   * Determines whether cookies and local / session storage items of the
   * specified category can be written, based on the user's permissions,
   * Global Privacy Control browser settings, and the current enforcement mode.
   * @param {Category} category The category in question.
   * @param {boolean} dangerouslyBypassGlobalPrivacyControl If true, the function will ignore
   * the user's Global Privacy Control browser settings and return the result of the other checks.
   * This should only be used in cases where the logic is checking for the user's underlying
   * stored permissions, and not for the purpose of determining the user's actual consent.
   * This parameter should only be used on the cookie settings page to display the correct
   * toggle state for the user's stored permissions.
   * @returns A promise for a Boolean indicating whether the category is permitted.
   */
  async isCategoryAllowed(
    category: Category,
    dangerouslyBypassGlobalPrivacyControl = false,
  ): Promise<boolean> {
    return this.isCategoryAllowedMaybeSync(
      category,
      dangerouslyBypassGlobalPrivacyControl,
    );
  }

  /**
   * Like "isCategoryAllowed" but may return a value instead of a promise if the value is already resolved.
   */
  isCategoryAllowedMaybeSync(
    category: Category,
    dangerouslyBypassGlobalPrivacyControl = false,
  ): boolean | Promise<boolean> {
    const permissions = Permissions.get();

    // If the category is necessary, allow it regardless of permissions.
    if (Categories[category].necessary) return true;

    // If the user has an enabled client-side Global Privacy Control signal
    // and the category is Advertising, reject it.
    if (
      !dangerouslyBypassGlobalPrivacyControl &&
      category === 'advertising' &&
      typeof navigator !== 'undefined'
    ) {
      const patchedNavigator = navigator as Navigator & {
        globalPrivacyControl?: boolean;
      };

      if (patchedNavigator?.globalPrivacyControl) {
        return false;
      }
    }

    // If the user has set permissions, we will honor them no matter what.
    if (permissions) {
      // Cookie recategorization project: remove when v2 cookies are fully
      // rolled out.

      // For v1 cookies, handle "functional" category checks as "statistics" or
      // "preferences" instead.
      if (permissions.version === '1' && category === 'functional') {
        return (
          !!permissions.get('statistics') || !!permissions.get('preferences')
        );
      }

      // If the client is using v2 cookie-perms, handle checks to "statistics" or
      // "preferences" as "functional" instead.
      if (
        permissions.version === '2' &&
        (category === 'statistics' || category === 'preferences')
      ) {
        return !!permissions.get('functional');
      }

      return !!permissions.get(category);
    }

    // We have to ensure that users within "enforcement zones" have given their
    // unambiguous consent before setting non-necessary cookies or local/ session storage items.
    // If the user has not set permissions, and we are currently in "restricted" mode,
    // reject all non-necessary cookies and items.
    const enforcementMode = this.getEnforcementModeMaybeSync();

    if (typeof enforcementMode === 'string') {
      if (enforcementMode === 'functional') {
        return category !== 'advertising';
      }
      return enforcementMode !== 'restricted';
    }

    return enforcementMode.then((value) => {
      if (value === 'functional') {
        return category !== 'advertising';
      }
      return value !== 'restricted';
    });
  }

  /**
   * Determines whether items in ALL of the specified categories can be written,
   * based on the user's permissions and the current enforcement mode.
   * @param {Array<Category>} categories The categories in question.
   * @returns A promise for a Boolean indicating whether all of the categories are permitted.
   */
  async areCategoriesAllowed(...categories: Category[]): Promise<boolean> {
    for (const category of categories) {
      const result = await this.isCategoryAllowed(category);

      if (!result) return false;
    }

    return true;
  }

  /**
   * Determine whether the current user should be shown a permissions banner prompting them to
   * set their permissions. They will only be shown a banner if:
   *
   * 1. They have not yet set permissions, AND
   * 2. The enforcement mode is set to "restricted" or "functional".
   *
   * @returns A promise for a boolean indicating whether the user should be shown a banner.
   */
  async shouldShowBanner(): Promise<boolean> {
    const permissions = this.getPermissions();

    // If the user has already set their permissions, don't show them the banner again.
    if (permissions) return false;

    // Show the user the prompt only if they are within an enforcement zone.
    const enforcementMode = await this.getEnforcementMode();

    return enforcementMode === 'restricted' || enforcementMode === 'functional';
  }

  /**
   * Registers a callback function which will be called whenever permissions change.
   * @param {PermissionsChangedCallback} callback The function which will be called.
   */
  watchPermissions(callback: PermissionsChangedCallback): void {
    this._watcher.addCallback(callback);
  }

  /**
   * Removes the specified callback function, causing it to no longer be called when
   * permissions change.
   * @param {PermissionsChangedCallback} callback The callback function to remove.
   */
  unwatchPermissions(callback: PermissionsChangedCallback): void {
    this._watcher.removeCallback(callback);
  }
}
