import {Link} from '@sail/ui';

const LinkLogoButton = () => {
  return (
    <Link href="https://link.com" target="_blank">
      <svg
        height="16"
        viewBox="0 0 72 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.12 3.67683C36.12 2.54882 37.0704 1.63202 38.1888 1.63202C39.3072 1.63202 40.2576 2.55362 40.2576 3.67683C40.2576 4.80003 39.3408 5.74563 38.1888 5.74563C37.0368 5.74563 36.12 4.82883 36.12 3.67683Z"
          fill="#011E0F"
        />
        <path
          d="M29.9808 1.92001H33.5808V22.08H29.9808V1.92001Z"
          fill="#011E0F"
        />
        <path
          d="M40.008 7.68001H36.3792V22.08H40.008V7.68001Z"
          fill="#011E0F"
        />
        <path
          d="M66.096 14.3904C68.8272 12.7104 70.6848 10.2096 71.4192 7.67524H67.7904C66.8448 10.0944 64.6752 11.9136 62.2896 12.6864V1.91523H58.6608V22.0752H62.2896V16.08C65.0592 16.7712 67.248 19.1664 67.9968 22.0752H71.6496C71.0928 19.0224 69.0048 16.1664 66.096 14.3904Z"
          fill="#011E0F"
        />
        <path
          d="M46.44 9.29283C47.3904 8.03043 49.2432 7.29602 50.7456 7.29602C53.5488 7.29602 55.8672 9.34563 55.872 12.4416V22.0752H52.2432V13.2432C52.2432 11.9712 51.6768 10.5024 49.8384 10.5024C47.6784 10.5024 46.4352 12.4176 46.4352 14.6592V22.0848H42.8064V7.68962H46.44V9.29283Z"
          fill="#011E0F"
        />
        <path
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37257 18.6274 0 12 0C5.37259 0 0 5.37257 0 12C0 18.6274 5.37259 24 12 24Z"
          fill="#00D66F"
        />
        <path
          d="M11.4479 4.80005H7.74707C8.46707 7.80965 10.5695 10.3824 13.1999 12C10.5647 13.6176 8.46707 16.1904 7.74707 19.2H11.4479C12.3647 16.416 14.9039 13.9968 18.0239 13.5024V10.4929C14.8991 10.0033 12.3599 7.58405 11.4479 4.80005Z"
          fill="#011E0F"
        />
      </svg>
    </Link>
  );
};

export default LinkLogoButton;
