{
  "standalone_onramp.AddThisToYourSite": "Gostaria de adicionar isso ao seu site?",
  "standalone_onramp.ChooseBetween": "Escolha entre crédito, débito e banco.",
  "standalone_onramp.CookieSettings": "Configurações de cookies",
  "standalone_onramp.FastAndSecure": "Rápido e seguro",
  "standalone_onramp.FlexibleWaysToPay": "Formas flexíveis de pagar",
  "standalone_onramp.GetStarted": "Começar",
  "standalone_onramp.Help": "Ajuda",
  "standalone_onramp.InstantlyBuyCrypto": "Compre criptomoedas instantaneamente",
  "standalone_onramp.LearnMore": "Saiba mais",
  "standalone_onramp.LowFees": "Tarifas baixas",
  "standalone_onramp.PayFaster": "Pague mais rápido com criptografia de dados que cumpre os mais rigorosos padrões PCI disponíveis.",
  "standalone_onramp.Privacy": "Privacidade",
  "standalone_onramp.SaveUpTo": "Economize até 50% em tarifas no checkout.",
  "standalone_onramp.Terms": "Termos",
  "standalone_onramp.ErrorMessage": "Ocorreu um erro ao carregar o aplicativo. Tente novamente em alguns minutos."
}