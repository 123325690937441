{
  "standalone_onramp.AddThisToYourSite": "Máte zájem o přidání této funkce na své stránky?",
  "standalone_onramp.ChooseBetween": "Vyberte si mezi kreditní, debetní a bankovní kartou.",
  "standalone_onramp.CookieSettings": "Nastavení souborů cookie",
  "standalone_onramp.FastAndSecure": "Rychle a bezpečně",
  "standalone_onramp.FlexibleWaysToPay": "Flexibilní způsoby placení",
  "standalone_onramp.GetStarted": "Začít",
  "standalone_onramp.Help": "Nápověda",
  "standalone_onramp.InstantlyBuyCrypto": "Okamžitě koupit kryptoměny",
  "standalone_onramp.LearnMore": "Více informací",
  "standalone_onramp.LowFees": "Nízké poplatky",
  "standalone_onramp.PayFaster": "Plaťte rychleji díky šifrování dat, které splňuje nejvyšší dostupné standardy PCI.",
  "standalone_onramp.Privacy": "Soukromí",
  "standalone_onramp.SaveUpTo": "Ušetřete až 50 % na poplatcích u pokladny.",
  "standalone_onramp.Terms": "Podmínky",
  "standalone_onramp.ErrorMessage": "Při načítání aplikace došlo k chybě. Zkuste to prosím za několik minut znovu."
}