{
  "standalone_onramp.AddThisToYourSite": "หากคุณสนใจเพิ่มบริการนี้ไว้บนเว็บไซต์",
  "standalone_onramp.ChooseBetween": "เลือกได้ว่าจะชำระด้วยบัตรเครดิต บัตรเดบิต หรือการโอนเงินผ่านธนาคาร",
  "standalone_onramp.CookieSettings": "การตั้งค่าคุกกี้",
  "standalone_onramp.FastAndSecure": "รวดเร็วและปลอดภัย",
  "standalone_onramp.FlexibleWaysToPay": "วิธีชำระเงินที่ยืดหยุ่น",
  "standalone_onramp.GetStarted": "เริ่มใช้งาน",
  "standalone_onramp.Help": "ความช่วยเหลือ",
  "standalone_onramp.InstantlyBuyCrypto": "ซื้อคริปโตได้ทันที",
  "standalone_onramp.LearnMore": "ดูข้อมูลเพิ่มเติม",
  "standalone_onramp.LowFees": "ค่าธรรมเนียมต่ำ",
  "standalone_onramp.PayFaster": "ชำระเงินได้รวดเร็วขึ้นด้วยการเข้ารหัสข้อมูลซึ่งเป็นไปตามมาตรฐาน PCI ที่รัดกุมที่สุด",
  "standalone_onramp.Privacy": "ความเป็นส่วนตัว",
  "standalone_onramp.SaveUpTo": "ประหยัดค่าธรรมเนียมสูงสุดถึง 50% ในขั้นตอนการชำระเงิน",
  "standalone_onramp.Terms": "ข้อกำหนด",
  "standalone_onramp.ErrorMessage": "เกิดข้อผิดพลาดขณะกำลังโหลดแอปพลิเคชัน โปรดลองอีกครั้งในอีกสักครู่"
}