/**
 * The list of all production domains on which we want to manage cookies.
 */
export const PRODUCTION_DOMAINS = [
  'stripe.com',
  'stripe.dev',
  'stripe.global',
  'stripe.partners',
  'increment.com',
  'link.co',
  'link.com',
] as const;

export const isProductionDomain = (hostname: string) => {
  return PRODUCTION_DOMAINS.some((domain) => {
    // Regex preemptively matches subdomains on the PRODUCTION_DOMAINS list
    // above even if there are none at the moment.
    // eslint-disable-next-line no-useless-escape
    const pattern = `(^|\.)${domain.replace(/\./g, '\\.')}$`;
    return new RegExp(pattern).test(hostname);
  });
};
