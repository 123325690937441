{
  "standalone_onramp.AddThisToYourSite": "Wil je dit toevoegen aan je website?",
  "standalone_onramp.ChooseBetween": "Kies voor creditcard, debitcard of je bankrekening.",
  "standalone_onramp.CookieSettings": "Cookie-instellingen",
  "standalone_onramp.FastAndSecure": "Snel en veilig",
  "standalone_onramp.FlexibleWaysToPay": "Flexibele manieren om te betalen",
  "standalone_onramp.GetStarted": "Aan de slag",
  "standalone_onramp.Help": "Help",
  "standalone_onramp.InstantlyBuyCrypto": "Direct cryptovaluta kopen",
  "standalone_onramp.LearnMore": "Ontdek meer",
  "standalone_onramp.LowFees": "Lage kosten",
  "standalone_onramp.PayFaster": "Betaal sneller met data-encryptie die voldoet aan de hoogste PCI-normen.",
  "standalone_onramp.Privacy": "Privacy",
  "standalone_onramp.SaveUpTo": "Bespaar tot 50% aan kosten bij het afrekenen.",
  "standalone_onramp.Terms": "Voorwaarden",
  "standalone_onramp.ErrorMessage": "Er is een fout opgetreden bij het laden van de applicatie. Probeer het over een paar minuten opnieuw."
}