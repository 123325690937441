{
  "standalone_onramp.AddThisToYourSite": "Želite li ovo dodati na svoje mrežno mjesto?",
  "standalone_onramp.ChooseBetween": "Odaberite između kreditne i debitne kartice ili bankovnog prijenosa.",
  "standalone_onramp.CookieSettings": "Postavke kolačića",
  "standalone_onramp.FastAndSecure": "Brzo i sigurno",
  "standalone_onramp.FlexibleWaysToPay": "Fleksibilni načini plaćanja",
  "standalone_onramp.GetStarted": "Započni",
  "standalone_onramp.Help": "Pomoć",
  "standalone_onramp.InstantlyBuyCrypto": "Trenutačno kupite kriptovalute",
  "standalone_onramp.LearnMore": "Saznajte više",
  "standalone_onramp.LowFees": "Niske naknade",
  "standalone_onramp.PayFaster": "Plaćajte brže uz šifriranje podataka koje zadovoljava najviše dostupne standarde industrije platnih kartica.",
  "standalone_onramp.Privacy": "Privatnost",
  "standalone_onramp.SaveUpTo": "Uštedite do 50 % na naknadama pri izvršenju plaćanja.",
  "standalone_onramp.Terms": "Uvjeti",
  "standalone_onramp.ErrorMessage": "Došlo je do pogreške prilikom učitavanja aplikacije. Pokušajte ponovno za nekoliko minuta."
}