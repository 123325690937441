import {view, Icon, Action, css} from '@sail/ui';
import {useIntl} from 'react-intl';
import {expand, chevronRight} from '@sail/icons/react/Icon';

import messages from 'src/utils/messages';
import LinkLogoButton from 'src/components/LinkLogoButton';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  isKnownSession: boolean | undefined;
}

const Header = ({setOpen, open, isKnownSession}: Props) => {
  const intl = useIntl();
  const helpIcon = open ? expand : chevronRight;
  return (
    <view.div
      css={{
        width: 'fill',
        stack: 'x',
        distribute: 'space-between',
      }}
    >
      {!isKnownSession ? <LinkLogoButton /> : <view.div />}
      <Action onPress={() => setOpen(!open)}>
        <view.div
          css={{
            fontWeight: css.value('600'),
            fontSize: css.value('14px'),
            lineHeight: css.value('20px'),
            stack: 'x',
            alignY: 'center',
            color: 'secondary',
          }}
        >
          {!open ? intl.formatMessage(messages.learnMore) : null}
          <Icon icon={helpIcon} size="small" />
        </view.div>
      </Action>
    </view.div>
  );
};

export default Header;
