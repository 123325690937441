import {ENFORCEMENT_MODE_PATH} from './paths';
import {resolveUrl} from './util';
import {EnforcementMode} from './types';

export const ENFORCEMENT_MODE_TIMEOUT_MS = 1000;

let resolvedEnforcementModePromise:
  | Promise<EnforcementMode>
  | null
  | undefined = null;

/**
 * Resolves the enforcement mode for the current user by making a fetch() call to the
 * IP geolocation endpoint.
 */
export const resolveEnforcementMode = (): Promise<EnforcementMode> => {
  if (!resolvedEnforcementModePromise) {
    const request = async () => {
      let mode: EnforcementMode = 'restricted';

      try {
        const response = await fetch(resolveUrl(ENFORCEMENT_MODE_PATH));
        const {mode: resultMode} = await response.json();
        if (
          resultMode === 'restricted' ||
          resultMode === 'open' ||
          resultMode === 'functional'
        ) {
          mode = resultMode;
        }
      } catch (err) {
        // If we encounter an error when looking up the enforcement mode,
        // fail closed and default to "restricted".
      }

      return mode;
    };

    const timeout = () =>
      new Promise<EnforcementMode>(
        (
          resolve: (
            _result: Promise<EnforcementMode> | EnforcementMode,
          ) => void,
        ) => {
          // If the request times out, we should fail closed and default to "restricted".
          setTimeout(() => resolve('restricted'), ENFORCEMENT_MODE_TIMEOUT_MS);
        },
      );

    resolvedEnforcementModePromise = Promise.race([request(), timeout()]);
  }

  return resolvedEnforcementModePromise;
};

/**
 * Manually sets the resolved enforcement mode. This should only be used during testing.
 * @private
 */
export const setResolvedEnforcementMode = (mode?: EnforcementMode | null) => {
  resolvedEnforcementModePromise = mode ? Promise.resolve(mode) : null;
};
