{
  "standalone_onramp.AddThisToYourSite": "有兴趣在您的网站上添加该功能？",
  "standalone_onramp.ChooseBetween": "选择信用卡、借记卡或银行账户。",
  "standalone_onramp.CookieSettings": "Cookie 设置",
  "standalone_onramp.FastAndSecure": "又快又安全",
  "standalone_onramp.FlexibleWaysToPay": "灵活的支付方式",
  "standalone_onramp.GetStarted": "开始",
  "standalone_onramp.Help": "帮助",
  "standalone_onramp.InstantlyBuyCrypto": "即时购买加密货币",
  "standalone_onramp.LearnMore": "了解更多",
  "standalone_onramp.LowFees": "费用低",
  "standalone_onramp.PayFaster": "通过符合支付卡行业 (PCI) 最高标准的数据加密技术，更快地进行支付。",
  "standalone_onramp.Privacy": "隐私",
  "standalone_onramp.SaveUpTo": "结账时节省高达 50% 的费用。",
  "standalone_onramp.Terms": "条款",
  "standalone_onramp.ErrorMessage": "加载应用程序时发生了错误。请几分钟后重试。"
}