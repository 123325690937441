{
  "standalone_onramp.AddThisToYourSite": "Berminat untuk menambahkan ciri ini kepada laman anda?",
  "standalone_onramp.ChooseBetween": "Pilih antara kredit, debit, dan bank.",
  "standalone_onramp.CookieSettings": "Tetapan Kuki",
  "standalone_onramp.FastAndSecure": "Cepat dan terjamin",
  "standalone_onramp.FlexibleWaysToPay": "Cara fleksibel untuk membuat pembayaran",
  "standalone_onramp.GetStarted": "Mulakan",
  "standalone_onramp.Help": "Bantuan",
  "standalone_onramp.InstantlyBuyCrypto": "Beli kripto dengan semerta",
  "standalone_onramp.LearnMore": "Ketahui selanjutnya",
  "standalone_onramp.LowFees": "Fi rendah",
  "standalone_onramp.PayFaster": "Bayar dengan lebih cepat melalui penyulitan data yang mematuhi piawaian PCI paling tinggi yang ada.",
  "standalone_onramp.Privacy": "Privasi",
  "standalone_onramp.SaveUpTo": "Penjimatan fi hingga 50% semasa bayar dan keluar.",
  "standalone_onramp.Terms": "Terma",
  "standalone_onramp.ErrorMessage": "Ralat berlaku ketika memuatkan aplikasi. Sila cuba lagi dalam masa beberapa minit."
}