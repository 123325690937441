{
  "standalone_onramp.AddThisToYourSite": "Želite dodati to na svoje spletno mesto?",
  "standalone_onramp.ChooseBetween": "Izbirajte med kreditno in debetno kartico ter bančnim nakazilom",
  "standalone_onramp.CookieSettings": "Nastavitve piškotkov",
  "standalone_onramp.FastAndSecure": "Varno in zanesljivo",
  "standalone_onramp.FlexibleWaysToPay": "Prilagodljivi načini plačila",
  "standalone_onramp.GetStarted": "Začnite",
  "standalone_onramp.Help": "Pomoč",
  "standalone_onramp.InstantlyBuyCrypto": "Takojšnji nakup kriptovalute",
  "standalone_onramp.LearnMore": "Več informacij",
  "standalone_onramp.LowFees": "Nizki stroški",
  "standalone_onramp.PayFaster": "Plačajte hitreje s šifriranjem podatkov, ki izpolnjujejo najstrožje zahteve standardov PCI, ki so na voljo.",
  "standalone_onramp.Privacy": "Zasebnost",
  "standalone_onramp.SaveUpTo": "Prihranite do 50 % stroškov pri izvedbi plačila.",
  "standalone_onramp.Terms": "Pogoji",
  "standalone_onramp.ErrorMessage": "Pri nalaganju aplikacije je prišlo do napake. Poskusite znova čez nekaj minut."
}