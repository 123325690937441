import {useState} from 'react';

export type WindowSize = 'compact' | 'regular';

const BREAKPOINT_WIDTH = 768;

const getWindowSize = (): WindowSize => {
  if (window.innerWidth <= BREAKPOINT_WIDTH) {
    return 'compact';
  } else {
    return 'regular';
  }
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize());

  window.addEventListener('resize', () => {
    setWindowSize(getWindowSize());
  });

  return windowSize;
};
