export const isLinkUrl = (url: string) => {
  return !!(
    url.match(/(\.)?link.com?\//i) ||
    (url.match('mkt-mydev.dev.stripe.me') && url.match('host=link')) ||
    (url.match('-mkt.tunnel.stripe.me') && url.match('host=link')) ||
    (url.match(/mkt(\.qa)?\.corp\.stripe\.com/i) && url.match('host=link')) ||
    url.match('link_app-mydev.dev.stripe.me') ||
    url.match('link_support_site-mydev.dev.stripe.com')
  );
};
