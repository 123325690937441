{
  "standalone_onramp.AddThisToYourSite": "Интересувате ли се да добавите това към сайта си?",
  "standalone_onramp.ChooseBetween": "Изберете между кредит, дебит и банкова сметка.",
  "standalone_onramp.CookieSettings": "Настройки на бисквитките",
  "standalone_onramp.FastAndSecure": "Бързо и сигурно",
  "standalone_onramp.FlexibleWaysToPay": "Гъвкави начини за плащане",
  "standalone_onramp.GetStarted": "Направете първите си стъпки",
  "standalone_onramp.Help": "Помощ",
  "standalone_onramp.InstantlyBuyCrypto": "Незабавно купуване на криптовалута",
  "standalone_onramp.LearnMore": "Научете повече",
  "standalone_onramp.LowFees": "Ниски такси",
  "standalone_onramp.PayFaster": "Плащайте по-бързо с криптиране на данни, което отговаря на най-високите налични PCI стандарти.",
  "standalone_onramp.Privacy": "Поверителност",
  "standalone_onramp.SaveUpTo": "Спестете до 50% от таксите при плащане.",
  "standalone_onramp.Terms": "Условия",
  "standalone_onramp.ErrorMessage": "Възникна грешка при зареждане на приложението. Моля, опитайте отново след няколко минути."
}