/**
 * An enum of categories to which a cookie can belong.
 */
export type Category =
  | 'authentication'
  | 'fraud-prevention'
  | 'security'
  | 'functionality'
  | 'preferences'
  | 'statistics'
  | 'advertising'
  // The below are new v2 cookie-perms categories
  | 'essential'
  | 'functional';

export type CategoryValue =
  | {necessary: true}
  | {necessary: false; token: string};

/**
 * The categories in which cookies and local storage items can be defined.
 *
 * An invariant expected by the Permissions class `toString` method is that
 * necessary cookies do not need a token, and not necessary cookies must
 * have a token.
 */
export const Categories: {
  [Property in Category]: CategoryValue;
} = {
  authentication: {necessary: true},
  'fraud-prevention': {necessary: true},
  security: {necessary: true},
  functionality: {necessary: true},
  preferences: {necessary: false, token: 'p'},
  statistics: {necessary: false, token: 's'},
  advertising: {necessary: false, token: 'a'},
  // The below are new v2 cookie-perms categories
  essential: {necessary: true},
  functional: {necessary: false, token: 'f'},
};
