{
  "standalone_onramp.AddThisToYourSite": "Máte záujem o pridanie na svoju stránku?",
  "standalone_onramp.ChooseBetween": "Vyberte si medzi kreditnou, debetnou a bankovou kartou.",
  "standalone_onramp.CookieSettings": "Nastavenia súborov cookie",
  "standalone_onramp.FastAndSecure": "Rýchle a zabezpečené",
  "standalone_onramp.FlexibleWaysToPay": "Flexibilné spôsoby za účelom platby",
  "standalone_onramp.GetStarted": "Začať",
  "standalone_onramp.Help": "Pomocník",
  "standalone_onramp.InstantlyBuyCrypto": "Ihneď kúpiť krypto",
  "standalone_onramp.LearnMore": "Ďalšie informácie",
  "standalone_onramp.LowFees": "Nízke poplatky",
  "standalone_onramp.PayFaster": "Plaťte rýchlejšie vďaka šifrovaniu údajov, ktoré spĺňa najvyššiu dostupnú úroveň štandardov PCI.",
  "standalone_onramp.Privacy": "Ochrana súkromia",
  "standalone_onramp.SaveUpTo": "Pri pokladni ušetríte až 50 % poplatkov.",
  "standalone_onramp.Terms": "Podmienky",
  "standalone_onramp.ErrorMessage": "Pri načítaní aplikácie došlo k chybe. Skúste to znova o pár minút."
}