const serialize = (object: object, scope?: string): string => {
  let result: string[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(object)) {
    const scopedKey = scope ? `${scope}[${key}]` : key;
    if (value && typeof value === 'object') {
      const nestedResult = serialize(value, scopedKey);
      if (nestedResult !== '') {
        result = [...result, nestedResult];
      }
    } else if (value !== undefined && value !== null) {
      result = [...result, `${scopedKey}=${encodeURIComponent(String(value))}`];
    }
  }
  return result.join('&').replace(/%20/g, '+');
};

export default serialize;
