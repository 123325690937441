import {arrowsOutwardSvg as Icon__small__arrowsOutwardSvg} from '../Icon__small/Icon__small--arrowsOutward.js'
import {arrowsOutwardSvg as Icon__xsmall__arrowsOutwardSvg} from '../Icon__xsmall/Icon__xsmall--arrowsOutward.js'

export var arrowsOutward = {
  name: 'arrowsOutward',
  category: 'icon',
  colorable: true,
  svg: Icon__small__arrowsOutwardSvg,
  alt: {
    Icon__small: Icon__small__arrowsOutwardSvg,
    Icon__xsmall: Icon__xsmall__arrowsOutwardSvg
  },
  combined: ['Icon__small', 'Icon__xsmall']
};
