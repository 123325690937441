{
  "standalone_onramp.AddThisToYourSite": "Interessado em adicionar isto à sua página de Internet?",
  "standalone_onramp.ChooseBetween": "Escolha entre crédito, débito e bancário.",
  "standalone_onramp.CookieSettings": "Definições de cookies",
  "standalone_onramp.FastAndSecure": "Rápido e seguro",
  "standalone_onramp.FlexibleWaysToPay": "Modos flexíveis para pagar",
  "standalone_onramp.GetStarted": "Começar",
  "standalone_onramp.Help": "Ajuda",
  "standalone_onramp.InstantlyBuyCrypto": "Compre criptomoedas instantaneamente",
  "standalone_onramp.LearnMore": "Saiba mais",
  "standalone_onramp.LowFees": "Taxas baixas",
  "standalone_onramp.PayFaster": "Pague mais rapidamente com a encriptação de dados que cumpre os mais elevados padrões das normas PCI disponíveis.",
  "standalone_onramp.Privacy": "Privacidade",
  "standalone_onramp.SaveUpTo": "Poupe até 50% em taxas na finalização de compra.",
  "standalone_onramp.Terms": "Condições",
  "standalone_onramp.ErrorMessage": "Ocorreu um erro ao carregar a aplicação. Tente novamente dentro de alguns minutos."
}