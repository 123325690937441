{
  "standalone_onramp.AddThisToYourSite": "Titħajjar iżżid dil-ħaġa mas-sit tiegħek?",
  "standalone_onramp.ChooseBetween": "Agħżel bejn kreditu, debitu u bank.",
  "standalone_onramp.CookieSettings": "Għażliet tal-Cookies",
  "standalone_onramp.FastAndSecure": "Malajr u siguri",
  "standalone_onramp.FlexibleWaysToPay": "Modi flessibbli kif tħallas",
  "standalone_onramp.GetStarted": "Ibda",
  "standalone_onramp.Help": "Għajnuna",
  "standalone_onramp.InstantlyBuyCrypto": "Ixtri l-kriptomuniti minnufih",
  "standalone_onramp.LearnMore": "Sir af iktar",
  "standalone_onramp.LowFees": "Tariffi baxxi",
  "standalone_onramp.PayFaster": "Ħallas iktar malajr b'kodifikazzjoni tad-dejta li ssir skont l-ogħla standards PCI fis-seħħ.",
  "standalone_onramp.Privacy": "Privatezza",
  "standalone_onramp.SaveUpTo": "Iffranka sa 50 % fuq it-tariffi meta tħallas.",
  "standalone_onramp.Terms": "Regoli",
  "standalone_onramp.ErrorMessage": "Seħħ żball waqt li qed intellgħu l-applikazzjoni. Erġa' pprova wara li jgħaddu ftit minuti."
}