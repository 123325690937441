{
  "standalone_onramp.AddThisToYourSite": "Interested in adding this to your site?",
  "standalone_onramp.ChooseBetween": "Choose between credit, debit, and bank.",
  "standalone_onramp.CookieSettings": "Cookie Settings",
  "standalone_onramp.FastAndSecure": "Fast and secure",
  "standalone_onramp.FlexibleWaysToPay": "Flexible ways to pay",
  "standalone_onramp.GetStarted": "Get started",
  "standalone_onramp.Help": "Help",
  "standalone_onramp.InstantlyBuyCrypto": "Instantly buy crypto",
  "standalone_onramp.LearnMore": "Learn more",
  "standalone_onramp.LowFees": "Low fees",
  "standalone_onramp.PayFaster": "Pay faster with data encryption that meet the highest PCI standards available.",
  "standalone_onramp.Privacy": "Privacy",
  "standalone_onramp.SaveUpTo": "Save up to 50% on fees at checkout.",
  "standalone_onramp.Terms": "Terms",
  "standalone_onramp.ErrorMessage": "An error occurred while loading the application. Please try again in a few minutes."
}