import {view, css, InlineDrawer} from '@sail/ui';
import {lock, card, fee} from '@sail/icons/react/Icon';
import React from 'react';
import Footer from 'src/components/Footer';
import LearnMoreItem from 'src/components/LearnMoreItem';
import messages from 'src/utils/messages';
import {useIntl} from 'react-intl';

interface Props {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showFullView: boolean;
}

const LearnMoreDrawer = ({setOpen, showFullView}: Props) => {
  const intl = useIntl();
  return (
    <InlineDrawer
      onClose={() => setOpen(false)}
      style={{backgroundColor: '#f6f8fa'}}
      size="medium"
    >
      <view.div
        css={{
          width: css.value('100%'),
          height: css.value('100%'),
          margin: css.value('0 auto'),
          padding: showFullView
            ? css.value('28px 20px 24px')
            : css.value('0px'),
          stack: 'y',
          gap: 'medium',
          distribute: 'space-between',
          fontFamily: css.value('"HW Nuckle", sans-serif'),
          fontWeight: css.value('500'),
          color: css.value('#1D3944'),
        }}
      >
        <view.div />
        <view.div>
          <view.div
            css={{
              fontWeight: css.value('600'),
              fontSize: css.value('20px'),
              lineHeight: css.value('28px'),
            }}
          >
            {intl.formatMessage(messages.instantlyBuyCrypto)}
          </view.div>
          <LearnMoreItem
            icon={lock}
            summary={intl.formatMessage(messages.fastAndSecure)}
            details={intl.formatMessage(messages.payFaster)}
          />
          <LearnMoreItem
            icon={card}
            summary={intl.formatMessage(messages.flexibleWaysToPay)}
            details={intl.formatMessage(messages.chooseBetween)}
          />
          <LearnMoreItem
            icon={fee}
            summary={intl.formatMessage(messages.lowFees)}
            details={intl.formatMessage(messages.saveUpTo)}
          />
        </view.div>
        <view.div>
          <Footer />
        </view.div>
      </view.div>
    </InlineDrawer>
  );
};

export default LearnMoreDrawer;
