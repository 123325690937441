{
  "standalone_onramp.AddThisToYourSite": "Doriți să adăugați la pagina dvs. web?",
  "standalone_onramp.ChooseBetween": "Alegeți între credit, debit și bancă.",
  "standalone_onramp.CookieSettings": "Setări privind modulele cookie",
  "standalone_onramp.FastAndSecure": "Rapid și securizat",
  "standalone_onramp.FlexibleWaysToPay": "Modalități flexibile de plată",
  "standalone_onramp.GetStarted": "Începeți",
  "standalone_onramp.Help": "Ajutor",
  "standalone_onramp.InstantlyBuyCrypto": "Cumpărați instant criptomonede",
  "standalone_onramp.LearnMore": "Aflați mai multe",
  "standalone_onramp.LowFees": "Taxe mici",
  "standalone_onramp.PayFaster": "Plătiți mai repede cu criptarea datelor care respectă cele mai înalte standarde PCI disponibile.",
  "standalone_onramp.Privacy": "Confidențialitate",
  "standalone_onramp.SaveUpTo": "Economisiți până la 50 % din taxe la finalizarea comenzii.",
  "standalone_onramp.Terms": "Termeni",
  "standalone_onramp.ErrorMessage": "A apărut o eroare la încărcarea aplicației. Vă rugăm să încercați din nou în câteva minute."
}