import {view, css} from '@sail/ui';
import messages from 'src/utils/messages';
import {useIntl} from 'react-intl';

export const ErrorMessage = () => {
  const intl = useIntl();

  return (
    <view.div
      css={{
        textAlign: css.value('center'),
      }}
    >
      {intl.formatMessage(messages.errorMessage)}
    </view.div>
  );
};
