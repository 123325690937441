import {css, Link, view, Divider} from '@sail/ui';
import messages from 'src/utils/messages';
import {useIntl} from 'react-intl';

const Footer = () => {
  const intl = useIntl();
  return (
    <view.div
      css={{
        stack: 'y',
        gap: 'medium',
        fontSize: css.value('14px'),
        lineHeight: css.value('20px'),
        fontWeight: css.value('600'),
        color: css.value('#687385'),
      }}
    >
      <view.div
        css={{
          stack: 'y',
          gap: 'small',
        }}
      >
        {intl.formatMessage(messages.addThisToYourSite)}
        <Link
          href="https://stripe.com/docs/crypto/overview"
          target="_blank"
          css={{
            color: css.value('#687385'),
            '&:hover': {filter: css.value('brightness(0.8)')},
            textDecoration: css.value('underline'),
          }}
        >
          {intl.formatMessage(messages.getStarted)}
        </Link>
      </view.div>
      <Divider />
      <view.div
        css={{
          stack: 'x',
          gap: 'medium',
        }}
      >
        <Link
          css={{
            color: css.value('#687385'),
            '&:hover': {filter: css.value('brightness(0.8)')},
          }}
          href="https://support.link.com/topics/crypto"
          target="_blank"
        >
          {intl.formatMessage(messages.help)}
        </Link>
        <Link
          css={{
            color: css.value('#687385'),
            '&:hover': {filter: css.value('brightness(0.8)')},
          }}
          href="https://link.com/terms/crypto-onramp"
          target="_blank"
        >
          {intl.formatMessage(messages.terms)}
        </Link>
        <Link
          css={{
            color: css.value('#687385'),
            '&:hover': {filter: css.value('brightness(0.8)')},
          }}
          href="https://link.com/privacy"
          target="_blank"
        >
          {intl.formatMessage(messages.privacy)}
        </Link>
        <Link
          css={{
            color: css.value('#687385'),
            '&:hover': {filter: css.value('brightness(0.8)')},
          }}
          href="https://link.com/cookie-settings"
          target="_blank"
        >
          {intl.formatMessage(messages.cookieSettings)}
        </Link>
      </view.div>
    </view.div>
  );
};

export default Footer;
