import {chevronRightSvg as Icon__small__chevronRightSvg} from '../Icon__small/Icon__small--chevronRight.js'
import {chevronRightSvg as Icon__xsmall__chevronRightSvg} from '../Icon__xsmall/Icon__xsmall--chevronRight.js'
import {chevronRightSvg as Icon__xxsmall__chevronRightSvg} from '../Icon__xxsmall/Icon__xxsmall--chevronRight.js'

export var chevronRight = {
  name: 'chevronRight',
  category: 'icon',
  colorable: true,
  svg: Icon__small__chevronRightSvg,
  alt: {
    Icon__small: Icon__small__chevronRightSvg,
    Icon__xsmall: Icon__xsmall__chevronRightSvg,
    Icon__xxsmall: Icon__xxsmall__chevronRightSvg
  },
  combined: ['Icon__small', 'Icon__xsmall', 'Icon__xxsmall']
};
