/**
 * The path for the cookie settings page, hosted by mkt-srv, which allows users to change
 * their permissions.
 */
export const COOKIE_SETTINGS_PAGE_PATH = '/cookie-settings';

/**
 * The path for our cookie policy.
 */
export const COOKIE_POLICY_PATH = '/cookies-policy/legal';

/**
 * The API endpoint which can be called to determine the enforcement mode for the current user.
 */
export const ENFORCEMENT_MODE_PATH = '/cookie-settings/enforcement-mode';

/**
 * The API endpoint to call to change the user's permissions.
 */
export const SET_PERMISSIONS_PATH = '/cookie-settings/update';
