import {Category} from '../types';

/**
 * Represents a local storage item read from the manifest.
 * @param {String} name The name of the item.
 * @param {$Shape<Cookie>} attrs Attributes read from the entry in the manifest.
 * @private
 */
export class LocalStorageItem {
  _regexp: RegExp | null | undefined;

  /**
   * The name of the item. For inexact items, this can be a pattern using asterisks as placeholders
   * for any characters.
   */
  name: string;

  /**
   * If true, allow name to represent a pattern instead of a direct match.
   */
  inexact: boolean;

  /**
   * The item's category (preferences, advertising, etc.)
   */
  category: Category;

  constructor(name: string, attrs: LocalStorageItem) {
    this.name = name;
    this.category = attrs.category;

    if (this.name.indexOf('*') >= 0) {
      this.inexact = true;
      this._regexp = new RegExp(name.replace('*', '.+'));
    } else {
      this.inexact = false;
    }
  }

  /**
   * Determines whether the given item name matches this item's name. Performs an inexact
   * match if this item supports inexact (pattern-based) matching.
   * @param {String} str The string to match.
   */
  isMatch(str: string) {
    return this.name === str || (this._regexp && this._regexp.test(str));
  }
}
