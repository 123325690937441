import {
  ResumeKnownMerchantSessionParams,
  CreateUnknownMerchantSessionParams,
} from 'src/api/types/api';
import type {Nullable, AmountParam} from 'src/types';

type UnknownSessionParams = {
  type: 'UNKNOWN_SESSION_PARAMS';
} & CreateUnknownMerchantSessionParams;

type KnownSessionParams = {
  type: 'KNOWN_SESSION_PARAMS';
} & ResumeKnownMerchantSessionParams;

// Treat search params as a singleton so we can clear them without affecting
// app logic
const SEARCH_PARAMS = new URL(window.location.toString()).searchParams;

const getQueryParam = (params: URLSearchParams, key: string) => {
  return params.get(key) || undefined;
};

const getArrayQueryParam = (params: URLSearchParams, key: string) => {
  const value = params.get(key);

  if (value) {
    return value.split(',');
  }
  return undefined;
};

const getAmountParam = (params: URLSearchParams): Nullable<AmountParam> => {
  const sourceAmount = {sourceAmount: getQueryParam(params, 'source_amount')};
  const sourceTotalAmount = {
    sourceTotalAmount: getQueryParam(params, 'source_total_amount'),
  };
  const destinationAmount = {
    destinationAmount: getQueryParam(params, 'destination_amount'),
  };

  let amountParams = {};

  // blegh
  [sourceAmount, sourceTotalAmount, destinationAmount].forEach((e) => {
    Object.values(e).forEach((v) => {
      if (v !== undefined) {
        amountParams = {
          ...amountParams,
          ...e,
        };
      }
    });
  });

  if (Object.keys(amountParams).length === 1) {
    return amountParams as AmountParam;
  }
  return undefined;
};

const getUnknownSessionParams = (
  params: URLSearchParams,
): UnknownSessionParams => {
  // default to livemode unless explicitly passed in livemode=false
  const testmode = getQueryParam(params, 'livemode') === 'false';
  const destinationNetwork = getQueryParam(params, 'destination_network');
  const destinationCurrency = getQueryParam(params, 'destination_currency');
  const supportedDestinationCurrencies = getArrayQueryParam(
    params,
    'supported_destination_currencies',
  );
  const supportedDestinationNetworks = getArrayQueryParam(
    params,
    'supported_destination_networks',
  );
  const amount = getAmountParam(params);
  const ledgerLive =
    getQueryParam(params, 'ledger_live') === 'true' || undefined;

  return {
    type: 'UNKNOWN_SESSION_PARAMS',
    livemode: !testmode,
    destinationNetwork,
    destinationCurrency,
    supportedDestinationCurrencies,
    supportedDestinationNetworks,
    amount,
    ledgerLive,
  };
};

const getKnownSessionParams = (
  params: URLSearchParams,
): Nullable<KnownSessionParams> => {
  const sessionHash = getQueryParam(params, 'session_hash');
  if (!sessionHash) {
    return undefined;
  }
  return {type: 'KNOWN_SESSION_PARAMS', sessionHash};
};

export const getSessionParams = (
  params: URLSearchParams = SEARCH_PARAMS,
): KnownSessionParams | UnknownSessionParams => {
  const knownSessionParams = getKnownSessionParams(params);
  const unknownSessionParams = getUnknownSessionParams(params);

  // prefer known to unknown params
  if (knownSessionParams) {
    return knownSessionParams;
  } else {
    return unknownSessionParams;
  }
};
