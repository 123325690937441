{
  "standalone_onramp.AddThisToYourSite": "Ενδιαφέρεστε να το προσθέσετε στον ιστότοπό σας;",
  "standalone_onramp.ChooseBetween": "Επιλέξτε ανάμεσα σε πίστωση, χρέωση και τράπεζα.",
  "standalone_onramp.CookieSettings": "Ρυθμίσεις cookie",
  "standalone_onramp.FastAndSecure": "Γρήγορο και ασφαλές",
  "standalone_onramp.FlexibleWaysToPay": "Ευέλικτοι τρόποι πληρωμής",
  "standalone_onramp.GetStarted": "Ξεκινήστε",
  "standalone_onramp.Help": "Βοήθεια",
  "standalone_onramp.InstantlyBuyCrypto": "Άμεση αγορά κρυπτονομισμάτων",
  "standalone_onramp.LearnMore": "Μάθετε περισσότερα",
  "standalone_onramp.LowFees": "Χαμηλές χρεώσεις",
  "standalone_onramp.PayFaster": "Πληρώστε ταχύτερα με κρυπτογράφηση δεδομένων που πληροί τα υψηλότερα διαθέσιμα πρότυπα PCI.",
  "standalone_onramp.Privacy": "Απόρρητο",
  "standalone_onramp.SaveUpTo": "Εξοικονομήστε έως και 50% σε χρεώσεις κατά την ολοκλήρωση αγοράς.",
  "standalone_onramp.Terms": "Όροι",
  "standalone_onramp.ErrorMessage": "Προέκυψε σφάλμα κατά τη φόρτωση της εφαρμογής. Δοκιμάστε ξανά σε λίγα λεπτά."
}