import {useState, useEffect} from 'react';
import {view, css, InlineDrawerProvider} from '@sail/ui';
import {AnimatePresence} from '@sail/engine';

import API, {isApiError} from 'src/api';
import CryptoOnrampSession from 'src/components/CryptoOnrampSession';
import {ErrorMessage} from 'src/components/ErrorMessage';
import LearnMoreDrawer from 'src/components/LearnMoreDrawer';
import {LocalizationWrapper} from 'src/components/LocalizationWrapper';
import Header from 'src/components/Header';
import {useWindowSize} from 'src/hooks/useWindowSize';
import {getSessionParams} from 'src/utils/SessionParams';
import {initializeAnalytics} from 'src/utils/analytics';

// Page styling copied from lib/consumer/link_app/frontend/src/utils/pageStyle.ts
const MIN_PAGE_SIZE = 320;
const MAX_PAGE_SIZE = 1120;
const MAX_CONTENT_SIZE = 464;

export const App = () => {
  const [onrampSessionClientSecret, setOnrampSessionClientSecret] =
    useState<string>();
  const [
    integratingMerchantPublishableKey,
    setIntegratingMerchantPublishableKey,
  ] = useState<string>();
  const [error, setError] = useState<string>();
  const [margin, setMargin] = useState(0);
  const [isKnownSession, setIsKnownSession] = useState<boolean>();
  const windowSize = useWindowSize();

  const showFullView = !!(windowSize === 'regular');
  const [open, setOpen] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // Setup Google analytics
  useEffect(() => {
    if (firstLoad) {
      initializeAnalytics();
      setFirstLoad(false);
      setOpen(showFullView);
    }
  }, [firstLoad, showFullView]);

  useEffect(() => {
    if (onrampSessionClientSecret) {
      return;
    }

    const sessionParams = getSessionParams();
    if (sessionParams.type === 'KNOWN_SESSION_PARAMS') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {type, ...others} = sessionParams;
      API.resumeKnownMerchantSession(others).then((response) => {
        if (isApiError(response)) {
          setError(response.error.message);
        } else {
          setOnrampSessionClientSecret(response.onramp_session_client_secret);
          setIntegratingMerchantPublishableKey(response.publishable_key);
        }
      });
      setIsKnownSession(true);
    } else if (sessionParams.type === 'UNKNOWN_SESSION_PARAMS') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const {type, ...others} = sessionParams;
      API.createUnknownMerchantSession(others).then((response) => {
        if (isApiError(response)) {
          setError(response.error.message);
        } else {
          setOnrampSessionClientSecret(response.onramp_session_client_secret);
          setIntegratingMerchantPublishableKey(response.publishable_key);
        }
      });
    }
  }, [
    onrampSessionClientSecret,
    setOnrampSessionClientSecret,
    setIntegratingMerchantPublishableKey,
  ]);

  return (
    <LocalizationWrapper>
      <view.div css={{...(isKnownSession && {backgroundColor: 'surface'})}}>
        <InlineDrawerProvider onSetSpacing={setMargin}>
          <view.div>
            <AnimatePresence>
              {open && showFullView ? (
                <LearnMoreDrawer
                  setOpen={setOpen}
                  showFullView={showFullView}
                />
              ) : null}
            </AnimatePresence>

            <view.div style={{marginRight: margin}}>
              <view.div
                css={{
                  width: css.value('100%'),
                  maxWidth: css.value(`${MAX_PAGE_SIZE}px`),
                  minWidth: css.value(`${MIN_PAGE_SIZE}px`),
                  height: css.value('100dvh'),
                  margin: css.value('0 auto'),
                  padding: showFullView
                    ? css.value('28px 20px 24px')
                    : css.value('0px'),
                  stack: 'y',
                  gap: 'medium',
                  alignX: 'center',
                  distribute: 'space-between',
                }}
              >
                {showFullView ? (
                  <Header
                    setOpen={setOpen}
                    open={open}
                    isKnownSession={isKnownSession}
                  />
                ) : null}
                <view.div
                  css={{
                    maxWidth: css.value(`${MAX_CONTENT_SIZE}px`),
                    width: css.value('100%'),
                    minHeight: css.value('550px'),
                    ...(showFullView && {
                      maxHeight: css.value('700px'),
                    }),
                    fontFamily: css.value('"HW Nuckle", sans-serif'),
                    fontWeight: css.value('500'),
                  }}
                >
                  {integratingMerchantPublishableKey &&
                  onrampSessionClientSecret ? (
                    <CryptoOnrampSession
                      integratingMerchantPublishableKey={
                        integratingMerchantPublishableKey
                      }
                      onrampSessionClientSecret={onrampSessionClientSecret}
                      showFullView={showFullView}
                    />
                  ) : null}
                  {error ? <ErrorMessage /> : null}
                </view.div>
                <view.div />
              </view.div>
            </view.div>
          </view.div>
        </InlineDrawerProvider>
      </view.div>
    </LocalizationWrapper>
  );
};
