import {view, Icon, css} from '@sail/ui';
import {IconAsset} from '@sail/icons/types';

interface Props {
  icon: IconAsset;
  summary: string;
  details: string;
}

const LearnMoreItem = ({icon, summary, details}: Props) => {
  return (
    <view.div
      css={{
        stack: 'x',
        gap: 'medium',
        margin: 'medium',
      }}
    >
      <view.div
        css={{
          minWidth: css.value('32px'),
          width: css.value('32px'),
          height: css.value('32px'),
          backgroundColor: css.value('#EBEEF1'),
          borderRadius: 'rounded',
          layout: 'column',
          alignY: 'center',
        }}
      >
        <view.div
          css={{
            layout: 'row',
            alignX: 'center',
          }}
        >
          <Icon icon={icon} size="small" />
        </view.div>
      </view.div>
      <view.div
        css={{
          stack: 'y',
          gap: 'xxsmall',
          fontWeight: css.value('500'),
        }}
      >
        <view.div
          css={{
            fontWeight: css.value('500'),
            fontSize: css.value('16px'),
            lineHeight: css.value('26px'),
          }}
        >
          {summary}
        </view.div>
        <view.div
          css={{
            fontSize: css.value('14px'),
            lineHeight: css.value('22px'),
            opacity: css.value('0.8'),
          }}
        >
          {details}
        </view.div>
      </view.div>
    </view.div>
  );
};

export default LearnMoreItem;
