import * as ApiTypes from 'src/api/types/api';
import serialize from 'src/api/serialize';
import {STANDALONE_SITE_URL, DEFAULT_LIVEMODE} from 'src/utils/helpers';

const httpRequest = async ({
  relativeUrl,
  method,
  params,
}: ApiTypes.ApiRequest) => {
  const serializedParams = serialize(params);

  const requestUrl = `${STANDALONE_SITE_URL}/${relativeUrl.replace(/^\//, '')}`;
  const requestData = new URLSearchParams(serializedParams);

  const response = await fetch(requestUrl, {
    method,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    },
    body: requestData,
  });

  return response.json();
};

const createDefaultSession = async () => {
  return httpRequest({
    relativeUrl: '/create_session',
    method: 'POST',
    params: {
      livemode: DEFAULT_LIVEMODE,
      referrer: document.referrer,
    },
  }) as Promise<ApiTypes.ApiResponse<ApiTypes.CreateSessionResponse>>;
};

const createUnknownMerchantSession = async (
  params: ApiTypes.CreateUnknownMerchantSessionParams,
) => {
  const {amount, ...others} = params;
  return httpRequest({
    relativeUrl: '/create_session',
    method: 'POST',
    params: {
      referrer: document.referrer,
      ...others,
      ...amount,
    },
  }) as Promise<ApiTypes.ApiResponse<ApiTypes.CreateSessionResponse>>;
};

const resumeKnownMerchantSession = async (
  params: ApiTypes.ResumeKnownMerchantSessionParams,
) => {
  return httpRequest({
    relativeUrl: '/resume_session',
    method: 'POST',
    params: {
      referrer: document.referrer,
      session_hash: params.sessionHash,
    },
  }) as Promise<ApiTypes.ApiResponse<ApiTypes.ResumeSessionResponse>>;
};

const validateRecordKey = (
  obj: Record<string, unknown>,
  name: string,
  expectedType: string,
): boolean => {
  // eslint-disable-next-line valid-typeof
  return obj && name in obj && typeof obj[name] === expectedType;
};

export const isApiError = (
  obj: Record<string, unknown>,
): obj is ApiTypes.ApiError => {
  return (
    validateRecordKey(obj, 'error', 'object') &&
    validateRecordKey(obj.error as Record<string, unknown>, 'message', 'string')
  );
};

export default {
  createDefaultSession,
  createUnknownMerchantSession,
  resumeKnownMerchantSession,
};
