{
  "standalone_onramp.AddThisToYourSite": "Intresserad av att lägga till detta på din webbplats?",
  "standalone_onramp.ChooseBetween": "Välj mellan kreditkort, bankkort och banköverföringar.",
  "standalone_onramp.CookieSettings": "Cookie-inställningar",
  "standalone_onramp.FastAndSecure": "Snabbt och säkert",
  "standalone_onramp.FlexibleWaysToPay": "Flexibla sätt att betala",
  "standalone_onramp.GetStarted": "Kom igång",
  "standalone_onramp.Help": "Hjälp",
  "standalone_onramp.InstantlyBuyCrypto": "Köp kryptovaluta direkt",
  "standalone_onramp.LearnMore": "Läs mer",
  "standalone_onramp.LowFees": "Lägre avgifter",
  "standalone_onramp.PayFaster": "Betala snabbare med datakryptering som uppfyller de högsta tillgängliga PCI-standarderna.",
  "standalone_onramp.Privacy": "Integritet",
  "standalone_onramp.SaveUpTo": "Spara upp till 50 % på avgifter i kassan.",
  "standalone_onramp.Terms": "Villkor",
  "standalone_onramp.ErrorMessage": "Ett fel uppstod när applikationen lästes in. Försök igen om några minuter."
}