const STANDALONE_MERCHANT_PUBLISHABLE_KEY_PROD =
  'pk_live_51MS6wjCFZMozWojKols6b07PbAZjrQ7if7JvCzgOwc8QIaS1XIUtBUgzvncU14gxKAAJ4b8uC3ckuMGOFfqfJbQj008wKI6h18';
const STANDALONE_MERCHANT_PUBLISHABLE_KEY_DEVBOX =
  'pk_test_devboxacct28DT589O8KAxCGbLmxyZ';
export const STANDALONE_MERCHANT_PUBLISHABLE_KEY =
  process.env.NODE_ENV === 'development'
    ? STANDALONE_MERCHANT_PUBLISHABLE_KEY_DEVBOX
    : STANDALONE_MERCHANT_PUBLISHABLE_KEY_PROD;

const CRYPTO_ONRAMP_JS_URL_PROD =
  'https://crypto-js.stripe.com/crypto-onramp-outer.js';
const CRYPTO_ONRAMP_JS_URL_DEVBOX = `https://${process.env.STRIPE_USER}-stripejs.tunnel.stripe.me/fingerprinted/js/crypto-onramp-outer.js`;
export const CRYPTO_ONRAMP_JS_URL =
  process.env.NODE_ENV === 'development'
    ? CRYPTO_ONRAMP_JS_URL_DEVBOX
    : CRYPTO_ONRAMP_JS_URL_PROD;

const STANDALONE_SITE_URL_PROD = 'https://crypto.link.com';
const DEVBOX_TUNNEL = false;
const STANDALONE_SITE_URL_DEVBOX = DEVBOX_TUNNEL
  ? `https://${process.env.STRIPE_USER}-crypto-standalone-site.tunnel.stripe.me`
  : `https://${process.env.STRIPE_USER}-crypto_standalone_site-mydev.dev.stripe.me`;
export const STANDALONE_SITE_URL =
  process.env.NODE_ENV === 'development'
    ? STANDALONE_SITE_URL_DEVBOX
    : STANDALONE_SITE_URL_PROD;

// TODO
export const DEFAULT_LIVEMODE = process.env.NODE_ENV !== 'development';
