{
  "standalone_onramp.AddThisToYourSite": "Chcesz dodać tę możliwość do swojej strony?",
  "standalone_onramp.ChooseBetween": "Wybierz kartę kredytową. debetową lub bank.",
  "standalone_onramp.CookieSettings": "Ustawienia plików cookie",
  "standalone_onramp.FastAndSecure": "Szybkie i bezpieczne",
  "standalone_onramp.FlexibleWaysToPay": "Elastyczne metody dokonywania płatności",
  "standalone_onramp.GetStarted": "Rozpocznij",
  "standalone_onramp.Help": "Pomoc",
  "standalone_onramp.InstantlyBuyCrypto": "Błyskawicznie kupuj kryptowaluty",
  "standalone_onramp.LearnMore": "Dowiedz się więcej",
  "standalone_onramp.LowFees": "Niskie opłaty",
  "standalone_onramp.PayFaster": "Płać szybciej dzięki szyfrowaniu danych na serwerach spełniających najwyższe dostępne standardy PCI.",
  "standalone_onramp.Privacy": "Prywatność",
  "standalone_onramp.SaveUpTo": "Zaoszczędź do 50% przy finalizacji.",
  "standalone_onramp.Terms": "Warunki",
  "standalone_onramp.ErrorMessage": "Wystąpił błąd podczas ładowania aplikacji. Spróbuj ponownie za kilka minut."
}